import { HttpClient } from '@angular/common/http';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatLabel } from '@angular/material/form-field';
import { MatTableModule } from '@angular/material/table';
import { environment } from '../../../environments/environment';
import { ReportService } from '../../shared/service/report.service';
import { MatCardModule } from '@angular/material/card';
import { SidenavService } from '../../shared/service/sidenav.service';


export interface ApiTests {
  id: number;
  name: string;
  controller: string;
  expectedResult: string;
  result: string;
  startTime: number | null;
  elapsedTime: number | null;
  test: string;
  specificCriteria: any;
}

@Component({
  selector: 'app-api-tests',
  standalone: true,
  imports: [MatButton, MatLabel, MatTableModule, MatCardModule],
  templateUrl: './api-tests.component.html',
  styleUrl: './api-tests.component.scss'
})
export class ApiTestsComponent implements OnInit, OnDestroy {

  private http = inject(HttpClient);
  private reportService = inject(ReportService);
  private sidenavService = inject(SidenavService);

  reportsToTest: ApiTests[] = [
    {
      id: 0, name: 'Etat locatif immeuble', controller: 'TenancyScheduleBuilding', expectedResult: '', result: '', startTime: null, elapsedTime: null, test: ''
      , specificCriteria: {}
    },
    {
      id: 100, name: 'Etat locatif bail', controller: 'TenancySchedule', expectedResult: '', result: '', startTime: null, elapsedTime: null, test: ''
      , specificCriteria: {}
    },
    {
      id: 150, name: 'Etat locatif mixte', controller: 'TenancyScheduleMixed', expectedResult: '', result: '', startTime: null, elapsedTime: null, test: ''
      , specificCriteria: {}
    },
    {
      id: 200, name: 'Etat locatif type de lot', controller: 'TenancyScheduleUnitType', expectedResult: '', result: '', startTime: null, elapsedTime: null, test: ''
      , specificCriteria: {}
    },
    {
      id: 300, name: 'Etat locatif lot', controller: 'TenancyScheduleUnit', expectedResult: '', result: '', startTime: null, elapsedTime: null, test: ''
      , specificCriteria: {}
    },
    {
      id: 400, name: 'Etat locatif lot détaillé', controller: 'TenancyScheduleUnitDetailed', expectedResult: '', result: '', startTime: null, elapsedTime: null, test: ''
      , specificCriteria: {}
    },
    {
      id: 420, name: 'Etat des lots vacants', controller: 'VacantUnits', expectedResult: '', result: '', startTime: null, elapsedTime: null, test: ''
      , specificCriteria: { }
    },
    {
      id: 430, name: "Etat d'occupation", controller: 'VacancyAnalysis', expectedResult: '', result: '', startTime: null, elapsedTime: null, test: ''
      , specificCriteria: { }
    },
    {
      id: 440, name: "Suivi mensuel de la vacance en m²", controller: 'VacancyPhysical', expectedResult: '', result: '', startTime: null, elapsedTime: null, test: ''
      , specificCriteria: { }
    },
    {
      id: 450, name: "Suivi mensuel de la vacance en %", controller: 'VacancyPhysicalPercent', expectedResult: '', result: '', startTime: null, elapsedTime: null, test: ''
      , specificCriteria: { }
    },
    {
      id: 460, name: "Suivi mensuel de la vacance en €", controller: 'VacancyPhysicalFin', expectedResult: '', result: '', startTime: null, elapsedTime: null, test: ''
      , specificCriteria: { }
    },
    {
      id: 480, name: 'Suivi des impayés en date comptable', controller: 'OutstandPayMonitoring', expectedResult: '', result: '', startTime: null, elapsedTime: null, test: ''
      , specificCriteria: { }
    },
    {
      id: 500, name: 'Balance âgée par bail', controller: 'OutstandingPayments', expectedResult: '', result: '', startTime: null, elapsedTime: null, test: ''
      , specificCriteria: { ReportType: 'BY_LEASE' }
    },
    {
      id: 600, name: 'Balance âgée par rubrique de facturation', controller: 'OutstandingPayments', expectedResult: '', result: '', startTime: null, elapsedTime: null, test: ''
      , specificCriteria: { ReportType: 'BY_RCI' }
    },
    {
      id: 700, name: 'Balance âgée par facture', controller: 'OutstandPayInvoice', expectedResult: '', result: '', startTime: null, elapsedTime: null, test: ''
      , specificCriteria: { ReportType: 'BY_INVOICE' }
    },
    {
      id: 800, name: 'Balance âgée par facture et rubrique de facturation', controller: 'OutstandPayInvoiveBillItem', expectedResult: '', result: '', startTime: null, elapsedTime: null, test: ''
      , specificCriteria: { ReportType: 'BY_INVOICE_RCI' }
    },
    // {
    //   id: 820, name: 'Evolution du patrimoine immobilier', controller: 'PortfolioEvolution', expectedResult: '', result: '', startTime: null, elapsedTime: null, test: ''
    //   , specificCriteria: { }
    // },
    {
      id: 880, name: 'Etat des DG et des cautions', controller: 'SecurityDepositsGuarantees', expectedResult: '', result: '', startTime: null, elapsedTime: null, test: ''
      , specificCriteria: { }
    },
    {
      id: 900, name: 'Fiche budgétaire', controller: 'BudgetMonitoring', expectedResult: '', result: '', startTime: null, elapsedTime: null, test: ''
      , specificCriteria: {}
    },
    {
      id: 1000, name: 'RGD - Factures comptabilisées', controller: 'GeneralExpenses', expectedResult: '', result: '', startTime: null, elapsedTime: null, test: ''
      , specificCriteria: { InvoiceType: 'ACCOUNTED' }
    },
    {
      id: 1100, name: 'RGD - Factures payées', controller: 'GeneralExpenses', expectedResult: '', result: '', startTime: null, elapsedTime: null, test: ''
      , specificCriteria: { InvoiceType: 'PAID' }
    },
  ];

  displayedColumns: string[] = ['name', 'controller', 'expectedResult', 'result', 'elapsedTime', 'test'];
  dataSource = this.reportsToTest;

  reportParams = environment.testsReportParams;

  launchTests(id: number, reportController: string) {
    this.setResultMessage(id, "...");
    this.updateSpecificCriteria(id);
    this.reportService.testsParams.set(this.reportParams);
    this.reportService.reportController.set(reportController);
    this.setStartTime(id);
    this.reportService.reportData$.subscribe((response) => {
      if (Object.keys(response).includes("error")) {
        this.setResultMessage(id, "Erreur : " + response.error);
      } else {
        this.setResultMessage(id, response.data.length.toString());
        this.setElapsedTime(id);
      }
    });
  }

  setExpectedResult(id: number, expectedResult: string) {
    this.reportsToTest.forEach((obj) => {
      if (obj.id === id) {
        obj.expectedResult = expectedResult;
      }
    });
  }

  setResultMessage(id: number, message: string) {
    this.reportsToTest.forEach((obj) => {
      if (obj.id === id) {
        obj.result = message;
      }
    });
  }

  setStartTime(id: number) {
    this.reportsToTest.forEach((obj) => {
      if (obj.id === id) {
        obj.startTime = new Date().getTime();
      }
    });
  }

  setElapsedTime(id: number) {
    this.reportsToTest.forEach((obj) => {
      if (obj.id === id) {
        obj.elapsedTime = obj.startTime === null ? null : (new Date().getTime() - obj.startTime) / 1000;
      }
    });
  }

  updateSpecificCriteria(id: number) {
    this.reportsToTest.forEach((obj) => {
      if (obj.id === id) {
        const specificCriteria = obj.specificCriteria;
        if (JSON.stringify(specificCriteria) !== "{}") {
          Object.keys(specificCriteria).forEach(key => {
            switch (key) {
              case 'ReportType':
                this.reportParams.filter['ReportType'] = specificCriteria['ReportType'];
                break;
              case 'BudgetTypeCode':
                this.reportParams.filter['BudgetTypeCode'] = specificCriteria['BudgetTypeCode'];
                break;
              case 'InvoiceType':
                this.reportParams.filter['InvoiceType'] = specificCriteria['InvoiceType'];
                break;
            }
          });
        }
      }
    });
  }

  clearServerCache() {
    this.http.post(`${environment.apiUrl}/api/cache/clear`, null).subscribe((response) => {
      alert('Cache serveur effacé');
    });
  }

  ngOnInit(): void {
    // Hide the sidenav for this page
    this.sidenavService.showSidenav = false;

    // Set the expected result for the environnement
    environment.testsReportExpectedResults.forEach((obj) => {
      this.setExpectedResult(obj.id, obj.expectedResult);
    })
  }

  ngOnDestroy(): void {
    // Reset tests parameters
    this.reportService.testsParams.set({});
  }

}
