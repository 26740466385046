export enum ExportFormat{
  CSV,
  XML,
  TXT
}

export enum PasswordRecoveryOption {
  TemporaryPassword,
  SMSDoubleAuth
}
