<div>
  @if (reportModelControl.value && reportModelControl.value["report_name"] && reportModelControl.value["template_name"])
  {
  <h4 class="report-title">{{ reportModelControl.value["report_name"] + ' (' + reportModelControl.value["template_name"] + ')' }}</h4>
  }
  @else {
  <h4 class="report-title">{{ReportName}}</h4>
  }
  <div class="container-row">

    <mat-card style="margin-right: 20px;">
      <mat-card-content>
        <app-report-dates></app-report-dates>
      </mat-card-content>
    </mat-card>

    <mat-card>
      <mat-card-content>
        <mat-form-field class="select-container" appearance="outline">
          <mat-label>{{ 'lblSelectReport' | translate }}</mat-label>
          <mat-select [formControl]="reportModelControl" required>
            <mat-option disabled>
              <ngx-mat-select-search placeholderLabel="{{ 'SEARCH' | translate }}"
                noEntriesFoundLabel="{{ 'NO DATA' | translate}}" [formControl]="filterControl">
              </ngx-mat-select-search>
            </mat-option>
            <mat-option [value]="{}">
              {{ ReportName }}
            </mat-option>
            @for (model of items() | async; track model) {
            <mat-option [value]="model">{{ model["template_name"] + ' (' + model["report_name"] + ')'}}</mat-option>
            }
          </mat-select>
          @if (reportModelControl.hasError('required')) {
          <mat-error>{{ 'lblBaseReportRequired' | translate }}</mat-error>
          }
        </mat-form-field>
        <mat-button-toggle-group [formControl]="exportTypeControl">
          <mat-button-toggle value="RAW">{{ "lblRaw" | translate }}</mat-button-toggle>
          <mat-button-toggle value="DETAILED">(fr) Mis en forme</mat-button-toggle>
        </mat-button-toggle-group>
        <mat-button-toggle-group [formControl]="exportFormatControl">
          <mat-button-toggle value="EXCELOPENXML">Excel</mat-button-toggle>
          <mat-button-toggle value="PDF">PDF</mat-button-toggle>
        </mat-button-toggle-group>
        <button mat-raised-button color="primary" (click)="exportReport()" style="margin-right: 10px;"><mat-icon>file_download</mat-icon>
          {{ "btnLaunchReport" | translate }}
        </button>
        <button mat-raised-button color="primary" [routerLink]="['/model']">
          <mat-icon>note_add</mat-icon>
          <span>{{ 'lblNewTemplate' | translate }}</span>
        </button>
      </mat-card-content>
    </mat-card>
  </div>

  <app-specific-criteria *ngIf="showSpecificCriteria"></app-specific-criteria>

  <br />
  <div class="dynamic-table">
    <app-dynamic-table [dataGetter]="getReportData" [columns]="columnsToDisplay" [analysisDate]="analysisDate()" [startDate]="startDate()"
      [stickyLeftColumnsCount]="stickyLeftColumnsCount" [stickyLeftColumns]="stickyLeftColumns" [isScrollable]="true"></app-dynamic-table>
  </div>
</div>
