<mat-error *ngIf="graphError()">
    {{graphError()}}
</mat-error>
<mat-card class="card-style">
    <mat-card-content>
        <div class="header">
            <div class="graph-title">
                <mat-label>{{ graphTitleCode | translate}}</mat-label>
            </div>
            <div class="fullscreen-button">
                <button mat-icon-button (click)="openFullscreenPlot(graphData(), graphLayout, graphTitleCode)">
                    <mat-icon>fullscreen</mat-icon>
                </button>
            </div>
        </div>
        <div class="rounded">
            <div id="plotly">
                <plotly-plot [data]="graphData()" [layout]="graphLayout" [useResizeHandler]="true"
                    [config]="plotlyConfig">
                </plotly-plot>
            </div>
        </div>
    </mat-card-content>
</mat-card>