import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceDDMMYYYY',
  standalone: true
})
export class ReplaceDDMMYYYYPipe implements PipeTransform {

  transform(value: string, code: string, analysisDate: Date | undefined, startDate: Date | undefined): string {
    const strDate: string | undefined = analysisDate?.toLocaleDateString("fr-FR");
    const strStartDate: string | undefined = startDate?.toLocaleDateString("fr-FR");
    let dateMinus1Month: Date | undefined = analysisDate === undefined ? undefined : new Date(analysisDate);
    if (dateMinus1Month != undefined && analysisDate != undefined) {
      dateMinus1Month.setMonth(analysisDate.getMonth() - 1);
    }
    const strDateMinus1Month: string | undefined = dateMinus1Month?.toLocaleDateString("fr-FR");

    if (strDate === undefined || strDateMinus1Month === undefined || strStartDate === undefined) {
      return value;
    }
    else {
      if (code.includes("BALANCE") && code.includes("START")) {
        return value.replaceAll("DD/MM/YYYY", strStartDate.substring(6) + "-" + strStartDate.substring(3, 5) + "-" + strStartDate.substring(0, 2));
      }
      else {
        return value.replaceAll("DDMMYYYY-1", (+strDate.substring(6) - 1).toString() + "-" + strDate.substring(3, 5) + "-" + strDate.substring(0, 2))
          .replaceAll("DDMMYYYY", strDate.substring(6) + "-" + strDate.substring(3, 5) + "-" + strDate.substring(0, 2))
          .replaceAll("DD/MM/YYYY", strDate.substring(6) + "-" + strDate.substring(3, 5) + "-" + strDate.substring(0, 2))
          .replaceAll("MM/YYYY-1", (+strDate.substring(6) - 1).toString() + "-" + strDate.substring(3, 5))
          .replaceAll("MM-1/YYYY", strDateMinus1Month.substring(6) + "-" + strDateMinus1Month.substring(3, 5))
          .replaceAll("MM/YYYY", strDate.substring(6) + "-" + strDate.substring(3, 5))
          .replaceAll("YYYY-2", (+strDate.substring(6) - 2).toString())
          .replaceAll("YYYY-1", (+strDate.substring(6) - 1).toString())
          .replaceAll("YYYY+1", (+strDate.substring(6) + 1).toString())
          .replaceAll("YYYY", strDate.substring(6));
      }
    }
  }

}
