<div class="sticky">
  <mat-toolbar class="nav-bar-style">

    <div class="container-logo">
      <a [routerLink]="['/home']">
        <img src="../../assets/pl-logo-vert_BD.png" alt="Home" class="logo">
      </a>
    </div>
    <span class="spacer"></span>
    <button *ngIf="isLoggedIn()" mat-button [matMenuTriggerFor]="reports" class="main-menu-button">
      <mat-icon>web</mat-icon>{{'menuMyReports' | translate}}
    </button>
    <button *ngIf="isLoggedIn()" mat-button [matMenuTriggerFor]="graphs" class="main-menu-button">
      <mat-icon>insights</mat-icon>{{'CHARTS' | translate}}
    </button>
    <button *ngIf="isLoggedIn()" mat-button [matMenuTriggerFor]="settings" class="main-menu-button">
      <mat-icon>settings</mat-icon>{{'MenuParam' | translate}}
    </button>
    <span class="spacer"></span>
    <span class="username">{{loggedUser()?.UserName}}</span>
    <div class="button-container">
      <button mat-raised-button color="primary" (click)="onLogout()">
        <span class="main-menu-button">{{'lsLoginStatusLogoutText' | translate}}</span>
      </button>
    </div>

  </mat-toolbar>

  <!-- Menu principal -->
  <mat-menu #reports="matMenu">
    <button mat-menu-item [matMenuTriggerFor]="tenancySchedules">
      <mat-icon>groups</mat-icon>
      <span class="sub-menu-button">{{'TENANCY_SCHEDULES' | translate}}</span>
    </button>
    <button mat-menu-item [matMenuTriggerFor]="vacancyAnalysis">
      <mat-icon>groups</mat-icon>
      <span class="sub-menu-button">{{'VACANCY' | translate}}</span>
    </button>
    <button mat-menu-item [matMenuTriggerFor]="agedBalances">
      <mat-icon>paid</mat-icon>
      <span class="sub-menu-button">{{'menuDirectOutstandingPayments' | translate}}</span>
    </button>
    <button mat-menu-item [matMenuTriggerFor]="assetMonitoring">
      <mat-icon>domain</mat-icon>
      <span class="sub-menu-button">{{'menuAssetMonitoring' | translate}}</span>
    </button>
    <button mat-menu-item [matMenuTriggerFor]="rentalMonitoring">
      <mat-icon>trending_up</mat-icon>
      <span class="sub-menu-button">{{'RENTAL_MONITORING' | translate}}</span>
    </button>
    <button mat-menu-item [matMenuTriggerFor]="expensesMonitoring">
      <mat-icon>paid</mat-icon>
      <span class="sub-menu-button">{{'EXPENSES_MONITORING' | translate}}</span>
    </button>
  </mat-menu>

  <!-- Sous-menus -->
  <mat-menu #tenancySchedules="matMenu">
    <button mat-menu-item [routerLink]="['/reports/report', 'TS_BUILDING']">
      <span class="item-menu-button">{{'lblTSBuildingReport' | translate}}</span>
    </button>
    <button mat-menu-item [routerLink]="['/reports/report', 'TS_LEASE']">
      <span class="item-menu-button">{{'lblTSLeaseReport' | translate}}</span>
    </button>
    <button mat-menu-item [routerLink]="['/reports/report', 'TS_MIXED']">
      <span class="item-menu-button">{{'lblTSMixedReport' | translate}}</span>
    </button>
    <button mat-menu-item [routerLink]="['/reports/report', 'TS_UNITTYPE']">
      <span class="item-menu-button">{{'lblTSUnitTypeReport' | translate}}</span>
    </button>
    <button mat-menu-item [routerLink]="['/reports/report', 'TS_UNIT']">
      <span class="item-menu-button">{{'lblTSUnitReport' | translate}}</span>
    </button>
    <button mat-menu-item [routerLink]="['/reports/report', 'TS_UNIT_DETAILED']">
      <span class="item-menu-button">{{'lblTSUnitDetailedReport' | translate}}</span>
    </button>
  </mat-menu>

  <mat-menu #vacancyAnalysis="matMenu">
    <button mat-menu-item [routerLink]="['/reports/report', 'VAU_REPORT']">
      <span class="item-menu-button">{{'lblVacantUnits' | translate}}</span>
    </button>
    <button mat-menu-item [routerLink]="['/reports/report', 'VAC_ANA_REPORT']">
      <span class="item-menu-button">{{'lblVACAnaReport' | translate}}</span>
    </button>
    <button mat-menu-item [routerLink]="['/reports/report', 'VAC_PHY_REPORT', 'SQM']">
      <span class="item-menu-button">{{'txtVacancyUnit' | translate}} {{'lblVacancyPhysicalSqm' | translate}}</span>
    </button>
    <button mat-menu-item [routerLink]="['/reports/report', 'VAC_PHY_REPORT', 'PERCENT']">
      <span class="item-menu-button">{{'txtVacancyUnit' | translate}} {{'lblVacancyPhysicalPercent' | translate}}</span>
    </button>
    <button mat-menu-item [routerLink]="['/reports/report', 'VAC_PHY_REPORT', 'FINANCIAL']">
      <span class="item-menu-button">{{'txtVacancyUnit' | translate}} {{'lblVacancyFinancial' | translate}}</span>
    </button>
  </mat-menu>

  <mat-menu #agedBalances="matMenu">
    <button mat-menu-item [routerLink]="['/reports/report', 'OPM_REPORT']">
      <span class="item-menu-button">{{'lblOPMReport' | translate}}</span>
    </button>
    <button mat-menu-item [routerLink]="['/reports/report', 'OUT_LEA_REPORT']">
      <span class="item-menu-button">{{'lblOUTReport' | translate}}</span>
    </button>
    <button mat-menu-item [routerLink]="['/reports/report', 'OUT_LEA_RCI_REP']">
      <span class="item-menu-button">{{'lblOUTLEARCIReport' | translate}}</span>
    </button>
    <button mat-menu-item [routerLink]="['/reports/report', 'OUT_INV_REPORT']">
      <span class="item-menu-button">{{'lblOUTINVReport' | translate}}</span>
    </button>
    <button mat-menu-item [routerLink]="['/reports/report', 'OUT_INV_RCI_REP']">
      <span class="item-menu-button">{{'lblOUTINVRCIReport' | translate}}</span>
    </button>
  </mat-menu>

  <mat-menu #assetMonitoring="matMenu">
    <button mat-menu-item [routerLink]="['/reports/report', 'EVP_REPORT']">
      <span class="item-menu-button">{{'lblEVPReport' | translate}}</span>
    </button>
    <button mat-menu-item [routerLink]="['/reports/report', 'YIM_REPORT', 'YMG']">
      <span class="item-menu-button">{{'lblGross' | translate}}</span>
    </button>
    <button mat-menu-item [routerLink]="['/reports/report', 'YIM_REPORT', 'YMN']">
      <span class="item-menu-button">{{'lblNet' | translate}}</span>
    </button>
    <button mat-menu-item [routerLink]="['/reports/report', 'YIM_REPORT', 'YMCG']">
      <span class="item-menu-button">{{'lblCapiGross' | translate}}</span>
    </button>
    <button mat-menu-item [routerLink]="['/reports/report', 'YIM_REPORT', 'YMCN']">
      <span class="item-menu-button">{{'lblCapiNet' | translate}}</span>
    </button>
    <button mat-menu-item [routerLink]="['/reports/report', 'PVA_REPORT']">
      <span class="item-menu-button">{{'lblPVAReport' | translate}}</span>
    </button>
  </mat-menu>

  <mat-menu #rentalMonitoring="matMenu">
    <button mat-menu-item [routerLink]="['/reports/report', 'SDG_REPORT']">
      <span class="item-menu-button">{{'lblSDGReport' | translate}}</span>
    </button>
  </mat-menu>

  <mat-menu #expensesMonitoring="matMenu">
    <button mat-menu-item [routerLink]="['/reports/report', 'BUM_REPORT']">
      <span class="item-menu-button">{{'lblBUMReport' | translate}}</span>
    </button>
    <button mat-menu-item [routerLink]="['/reports/report', 'GEC_REPORT']">
      <span class="item-menu-button">{{'lblGECReport' | translate}}</span>
    </button>
    <button mat-menu-item [routerLink]="['/reports/report', 'GEP_REPORT']">
      <span class="item-menu-button">{{'lblGEPReport' | translate}}</span>
    </button>
  </mat-menu>

  <mat-menu #graphs="matMenu">
    <button mat-menu-item [routerLink]="['/graphs/patrimony']">
      <mat-icon>business</mat-icon>
      <span class="item-menu-button">{{ 'lblPatrimony' | translate }}</span>
    </button>
    <button mat-menu-item [routerLink]="['/graphs/occupancy']">
      <mat-icon>groups</mat-icon>
      <span class="item-menu-button">{{ 'lblOccupancy' | translate }}</span>
    </button>
    <button mat-menu-item [routerLink]="['/graphs/income']">
      <mat-icon>trending_up</mat-icon>
      <span class="item-menu-button">{{ 'lblRentalIncome' | translate }}</span>
    </button>
    <button mat-menu-item [routerLink]="['/graphs/paiement']">
      <mat-icon>paid</mat-icon>
      <span class="item-menu-button">{{ 'Table3Attr4' | translate }}</span>
    </button>
    <button mat-menu-item [routerLink]="['/graphs/risk']">
      <mat-icon>report_problem</mat-icon>
      <span class="item-menu-button">{{ 'lblRisk' | translate }}</span>
    </button>
  </mat-menu>

  <mat-menu #settings="matMenu">
    <button mat-menu-item [routerLink]="['/settings/account']">
      <mat-icon>account_circle</mat-icon>
      <span class="item-menu-button">{{'pageManageTitle' | translate}}</span>
    </button>
    <button mat-menu-item [routerLink]="['/model']">
      <mat-icon>note_add</mat-icon>
      <span class="item-menu-button">{{ 'lblNewTemplate' | translate }}</span>
    </button>
    <button mat-menu-item [routerLink]="['/settings/customReport']">
      <mat-icon>summarize</mat-icon>
      <span class="item-menu-button">{{ 'lblRPTPage' | translate }}</span>
    </button>
    <button mat-menu-item [routerLink]="['/settings/lease-repository']">
      <mat-icon>document_scanner</mat-icon>
      <span class="item-menu-button">{{'lblLeaseRepository' | translate}}</span>
    </button>
  </mat-menu>

</div>
