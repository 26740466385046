import { Injectable, inject, signal } from '@angular/core';
import { AppService } from './app.service';
import { filter, map, Observable, switchMap } from 'rxjs';
import { SidenavService } from './sidenav.service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { SelectDynamicData, ServerSideResponse } from '../model/types';

@Injectable({
  providedIn: 'root'
})
export class SpecificCriteriaService {

  private appService = inject(AppService);
  private sidenavService = inject(SidenavService);
  private http = inject(HttpClient);

  selectedLeaseActiveCode = signal<string | null>("0");

  selectedFullLeaseActiveCode = signal<string | null>("0");

  selectedReferenceYear = signal<number | null>(new Date().getFullYear());

  selectedBudgetTypeCode = signal<string | null>("OPEX");

  selectedTaxTypeCode = signal<string | null>("HT");

  selectedLeaseLitigationCode = signal<string | null>("0");

  selectedBudgetTypeCodeRGD = signal<string | null>("OPEX");

  selectedBudgetStatusCode = signal<string | null>("ALL");

  selectedExpenseTypeCode = signal<string | null>("ALL");

  selectedUnitTypeListCode = signal<SelectDynamicData[]>([]);

  selectedVacancyTypeListCode = signal<SelectDynamicData[]>([]);

  selectedNaturesOfOccupancy = signal<SelectDynamicData[]>([]);

  selectedVacancyArea = signal<string>("0");

  selectedVacancyMonths = signal<string>("0");

  selectedVacancyRate = signal<string>("0");

  selectedLeaseTypesListCode = signal<SelectDynamicData[]>([]);

  selectedHeadlineNetInitYield = signal<string>("0");

  selectedEffectiveNetInitYield = signal<string>("0");

  selectedCutOffRevisionDate = signal<Date | null>(null);

  selectedCutOffDate = signal<Date | null>(null);

  getLeaseActiveLabel(code: string | null): string {
    const language = localStorage.getItem('userLocale') || navigator.language;
    switch (code) {
      // case "0":
      //   return this.appService.getTranslation(language, "lblSelectLeaseActiveAll");
      case "0":
        return this.appService.getTranslation(language, "lblSelectLeaseAllWithOutstandingPayments");
      case "1":
        return this.appService.getTranslation(language, "lblSelectLeaseActiveWithOutstandingPayments");
      case "2":
        return this.appService.getTranslation(language, "lblSelectLeaseInactiveWithOutstandingPayments");
      case "3":
        return this.appService.getTranslation(language, "lblSelectLeaseInLitigation");
      default:
        return "";
    }
  }

  getFullLeaseActiveLabel(code: string | null): string {
    const language = localStorage.getItem('userLocale') || navigator.language;
    switch (code) {
      case "0":
        return this.appService.getTranslation(language, "lblSelectLeaseActiveAll");
      case "1":
        return this.appService.getTranslation(language, "lblSelectLeaseAllWithOutstandingPayments");
      case "2":
        return this.appService.getTranslation(language, "lblSelectLeaseActiveWithOutstandingPayments");
      case "3":
        return this.appService.getTranslation(language, "lblSelectLeaseInactiveWithOutstandingPayments");
      case "4":
        return this.appService.getTranslation(language, "lblSelectLeaseInLitigation");
      default:
        return "";
    }
  }

  getBudgetTypeLabel(code: string | null): string {
    const language = localStorage.getItem('userLocale') || navigator.language;
    switch (code) {
      case "OPEX":
        return this.appService.getTranslation(language, "lblBudgetTypeListOpex");
      case "CAPEX":
        return this.appService.getTranslation(language, "lblBudgetTypeListCapex");
      case "PRODUCT":
        return this.appService.getTranslation(language, "lblBudgetTypeListProduct");
      case "OPEXPRODUCT":
        return this.appService.getTranslation(language, "lblBudgetTypeListOpexProduct");
      case "OPEXCAPEX":
        return this.appService.getTranslation(language, "lblBudgetTypeListOpexCapex");
      default:
        return "";
    }
  }

  getBudgetTypeRGDLabel(code: string | null): string {
    const language = localStorage.getItem('userLocale') || navigator.language;
    switch (code) {
      case "ALL":
        return this.appService.getTranslation(language, "lblBudgetTypeAll");
      case "OPEX":
        return this.appService.getTranslation(language, "lblBudgetTypeOpex");
      case "CAPEX":
        return this.appService.getTranslation(language, "lblBudgetTypeCapex");
      default:
        return "";
    }
  }

  getBudgetStatusLabel(code: string | null): string {
    const language = localStorage.getItem('userLocale') || navigator.language;
    switch (code) {
      case "ALL":
        return this.appService.getTranslation(language, "lblBudgetStatusAll");
      case "OPEN":
        return this.appService.getTranslation(language, "lblBudgetStatusOpen");
      case "CLOSED":
        return this.appService.getTranslation(language, "lblBudgetStatusClosed");
      default:
        return "";
    }
  }

  getExpenseTypeLabel(code: string | null): string {
    const language = localStorage.getItem('userLocale') || navigator.language;
    switch (code) {
      case "ALL":
        return this.appService.getTranslation(language, "lblExpenseTypeAll");
      case "RECOVERABLE":
        return this.appService.getTranslation(language, "lblExpenseTypeRecoverable");
      case "NON_RECOVERABLE":
        return this.appService.getTranslation(language, "lblExpenseTypeNonRecoverable");
      default:
        return "";
    }
  }

  getTaxTypeLabel(code: string | null): string {
    const language = localStorage.getItem('userLocale') || navigator.language;
    switch (code) {
      case "HT":
        return this.appService.getTranslation(language, "lblTaxeTypeHT");
      case "TTC":
        return this.appService.getTranslation(language, "lblTaxeTypeTTC");
      default:
        return "";
    }
  }

  getLeaseLitigationLabel(code: string | null): string {
    const language = localStorage.getItem('userLocale') || navigator.language;
    switch (code) {
      case "0":
        return this.appService.getTranslation(language, "lblSelectAllLeases");
      case "1":
        return this.appService.getTranslation(language, "lblSelectActivesLeases");
      case "2":
        return this.appService.getTranslation(language, "lblSelectInactivesLeases");
      case "3":
        return this.appService.getTranslation(language, "lblSelectInLitigationLeases");
      default:
        return "";
    }
  }

  getUnitTypeList():  Observable<ServerSideResponse>{
    const params$ = this.sidenavService.selectedImportDate$.pipe(
      filter(importDate => importDate !== null), // Assurez-vous que la date d'import n'est pas null
      map(importDate => ({
        Filter: {
          ImportDate: importDate,
          LanguageCode: localStorage.getItem('userLocale') || navigator.language,
        },
        mappings: null,
        additionalFields: null,
        Format: 0,
        draw: 0,
        columns: null,
        order: null,
        start: 0,
        length: 0,
        search: null,
      }))
    );

    return params$.pipe(
      switchMap(params =>
        this.http.post<ServerSideResponse>(`${environment.apiUrl}/api/UnitTypesList/Get`, params)
      )
    );
  }

  getVacancyTypeList():  Observable<ServerSideResponse>{
    const params$ = this.sidenavService.selectedImportDate$.pipe(
      filter(importDate => importDate !== null), // Assurez-vous que la date d'import n'est pas null
      map(importDate => ({
        Filter: {
          ImportDate: importDate,
          LanguageCode: localStorage.getItem('userLocale') || navigator.language,
        },
        mappings: null,
        additionalFields: null,
        Format: 0,
        draw: 0,
        columns: null,
        order: null,
        start: 0,
        length: 0,
        search: null,
      }))
    );

    return params$.pipe(
      switchMap(params =>
        this.http.post<ServerSideResponse>(`${environment.apiUrl}/api/VacancyTypesList/Get`, params)
      )
    );
  }

  getNaturesOfOccupancyList():  Observable<ServerSideResponse>{
    const params$ = this.sidenavService.selectedImportDate$.pipe(
      filter(importDate => importDate !== null), // Assurez-vous que la date d'import n'est pas null
      map(importDate => ({
        Filter: {
          ImportDate: importDate,
          LanguageCode: localStorage.getItem('userLocale') || navigator.language,
        },
        mappings: null,
        additionalFields: null,
        Format: 0,
        draw: 0,
        columns: null,
        order: null,
        start: 0,
        length: 0,
        search: null,
      }))
    );

    return params$.pipe(
      switchMap(params =>
        this.http.post<ServerSideResponse>(`${environment.apiUrl}/api/NaturesOfOccupancyList/Get`, params)
      )
    );
  }

  getLeaseTypesList():  Observable<ServerSideResponse>{
    const params$ = this.sidenavService.selectedImportDate$.pipe(
      filter(importDate => importDate !== null), // Assurez-vous que la date d'import n'est pas null
      map(importDate => ({
        Filter: {
          ImportDate: importDate,
          LanguageCode: localStorage.getItem('userLocale') || navigator.language,
        },
        mappings: null,
        additionalFields: null,
        Format: 0,
        draw: 0,
        columns: null,
        order: null,
        start: 0,
        length: 0,
        search: null,
      }))
    );

    return params$.pipe(
      switchMap(params =>
        this.http.post<ServerSideResponse>(`${environment.apiUrl}/api/LeaseTypesList/Get`, params)
      )
    );
  }

  getCutOffDateList():  Observable<ServerSideResponse>{
    const params$ = this.sidenavService.selectedImportDate$.pipe(
      filter(importDate => importDate !== null), // Assurez-vous que la date d'import n'est pas null
      map(importDate => ({
        Filter: {
          ImportDate: importDate
        },
        mappings: null,
        additionalFields: null,
        Format: 0,
        draw: 0,
        columns: null,
        order: null,
        start: 0,
        length: 0,
        search: null,
      }))
    );

    return params$.pipe(
      switchMap(params =>
        this.http.post<ServerSideResponse>(`${environment.apiUrl}/api/CutOffDateList/Get`, params)
      )
    );
  }

  getCutOffRevisDateList(cutOffDate: string):  Observable<ServerSideResponse>{
    const params = {
        Filter: {
          CutOffDate: cutOffDate
        },
        mappings: null,
        additionalFields: null,
        Format: 0,
        draw: 0,
        columns: null,
        order: null,
        start: 0,
        length: 0,
        search: null,
      };

    return this.http.post<ServerSideResponse>(`${environment.apiUrl}/api/CutOffRevisDateList/Get`, params);
  }

}
