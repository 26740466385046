import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CreateUpdateReportTemplateRequestModel, ServerSideResponse } from '../model/types';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ModelReportService {

  private http = inject(HttpClient);


  constructor() {
  }

  getReportData() : Observable<ServerSideResponse>{
    const params = {
      mappings: null,
      additionalFields: null,
      Format: 0,
      draw: 0,
      columns: null,
      order: null,
      start: 0,
      length: 0,
      search: null,
      filter: {
        ReportCode: '',
        LanguageCode: localStorage.getItem('userLocale') || navigator.language,
      }
    };
    return this.http.post<ServerSideResponse>(`${environment.apiUrl}/api/ReportData/Get`, params);
  }

  getReportTemplateData(reportCode: string, idReportTemplate: number, returnOnlySelectedCols: string) : Observable<ServerSideResponse>{
    const params = {
      mappings: null,
      additionalFields: null,
      Format: 0,
      draw: 0,
      columns: null,
      order: null,
      start: 0,
      length: 0,
      search: null,
      filter: {
        ReportCode: reportCode,
        IdReportTemplate: idReportTemplate,
        LanguageCode: localStorage.getItem('userLocale') || navigator.language,
        ReturnOnlySelectedColumns: returnOnlySelectedCols
      }
    };
    return this.http.post<ServerSideResponse>(`${environment.apiUrl}/api/ReportTemplateData/Get`, params);
  }

  createUpdateReportTemplate(createUpdateReportTemplate: CreateUpdateReportTemplateRequestModel) : Observable<ServerSideResponse>{
    const params = {
      mappings: null,
      additionalFields: null,
      Format: 0,
      draw: 0,
      columns: null,
      order: null,
      start: 0,
      length: 0,
      search: null,
      filter: {...createUpdateReportTemplate}
    };
    return this.http.post<ServerSideResponse>(`${environment.apiUrl}/api/CreateUpdateReportTemplate/Invoke`, params);
  }

  getReportTemplates(reportCode: string | null, username: string) : Observable<ServerSideResponse>{
    const params = {
      mappings: null,
      additionalFields: null,
      Format: 0,
      draw: 0,
      columns: null,
      order: null,
      start: 0,
      length: 0,
      search: null,
      filter: {
        LanguageCode: localStorage.getItem('userLocale') || navigator.language,
        ReportCode: reportCode,
        User: username
      }
    };
    return this.http.post<ServerSideResponse>(`${environment.apiUrl}/api/ReportTemplates/Get`, params);
  }

  deleteReportTemplate(idReportTemplate: number) : Observable<ServerSideResponse>{
    const params = {
      mappings: null,
      additionalFields: null,
      Format: 0,
      draw: 0,
      columns: null,
      order: null,
      start: 0,
      length: 0,
      search: null,
      filter: {
        IdReportTemplate: idReportTemplate
      }
    };
    return this.http.post<ServerSideResponse>(`${environment.apiUrl}/api/DeleteReportTemplate/Invoke`, params);
  }
}
