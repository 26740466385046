<mat-form-field *ngIf="!showDateRange" appearance="outline">
  <mat-label>{{"lblAnalysisDate" | translate}}</mat-label>
  <input matInput [matDatepicker]="picker" [formControl]="analysisDateControl()" (dateChange)="analysisDateChange()">
  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
<mat-form-field *ngIf="showDateRange" appearance="outline">
  <mat-label>{{"lblStartDate" | translate}} - {{"lblEndDate" | translate}}</mat-label>
  <mat-date-range-input [formGroup]="rangeGroup()" [rangePicker]="rangePicker">
    <input matStartDate formControlName="start" placeholder='{{"lblStartDate" | translate}}' (dateChange)="rangeDateChange()">
    <input matEndDate formControlName="end" placeholder='{{"lblEndDate" | translate}}' (dateChange)="rangeDateChange()">
  </mat-date-range-input>
  <mat-datepicker-toggle matIconSuffix [for]="rangePicker"></mat-datepicker-toggle>
  <mat-date-range-picker #rangePicker></mat-date-range-picker>

  @if (rangeGroup().controls.start.hasError('matStartDateInvalid')) {
  <mat-error>(en) Invalid start date</mat-error>
  }
  @if (rangeGroup().controls.end.hasError('matEndDateInvalid')) {
  <mat-error>(en) Invalid end date</mat-error>
  }
</mat-form-field>
