import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  computed,
  CUSTOM_ELEMENTS_SCHEMA,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Signal,
  ViewChild,
} from '@angular/core';
import {
  MatOption,
  MatSelect,
  MatSelectModule,
} from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { AsyncPipe, CommonModule } from '@angular/common';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { BehaviorSubject, map, ReplaySubject, Subject, takeUntil } from 'rxjs';
import { SelectDynamicData } from '../../shared/model/types';

@Component({
  selector: 'app-multi-select-search-dropdown',
  templateUrl: './multi-select-search-dropdown.component.html',
  styleUrls: ['./multi-select-search-dropdown.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatTableModule,
    MatTabsModule,
    FormsModule,
    MatIconModule,
    AsyncPipe,
    CommonModule,
    NgxMatSelectSearchModule,
    MatCheckboxModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MultiSelectSearchdropdownComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('select') select!: MatSelect;
  /**
   * List of items to display in the dropdown.
   * Utilisé pour fournir la liste des éléments du dropdown.
   */
  @Input() items!: Signal<SelectDynamicData[] | undefined>;
  /**
   * Label for the dropdown.
   * Utilisé pour définir le libellé affiché au-dessus du dropdown.
   */
  @Input() label!: string;
  /**
   * Callback function triggered when the dropdown is closed.
   * Utilisé pour exécuter une action spécifique lorsque le dropdown se ferme.
   */
  @Input() onClosedCallback?: (selectedItems: SelectDynamicData[] | null) => void;

  /**
   * The width of the dropdown in pixels.
   * Utilisé pour définir la largeur du composant dropdown.
   *
   * @example
   * <app-multi-select-search-dropdown [width]="350"></app-multi-select-search-dropdown>
   */
  @Input() width?: number;

  selectedControl = new FormControl<SelectDynamicData[]>([]);
  public filterControl: FormControl = new FormControl();
  public filteredList = new BehaviorSubject<SelectDynamicData[]>([]);
  allSelected: boolean = false;

  private cdr = inject(ChangeDetectorRef);
  private _onDestroy = new Subject<void>();

  itemsFormatted = computed(() => {
    this.filteredList.next(this.items()!);

    this.filterControl.valueChanges.pipe(
      takeUntil(this._onDestroy),
      map(search => this.filterItems(this.items()!, search))
    ).subscribe(filtered => {
      this.filteredList.next(filtered);
    });
    return this.filteredList;
  });

  constructor() { }

  ngOnInit(): void {
    this.filteredList.next(this.items()!);
    this.filterControl.valueChanges.pipe(
      takeUntil(this._onDestroy),
      map(search => this.filterItems(this.items()!, search))
    ).subscribe(filtered => {
      this.filteredList.next(filtered);
    });
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  private filterItems(items: SelectDynamicData[], search: string): SelectDynamicData[] {
    if (!search) {
      return items;
    }
    search = search.toLowerCase();
    return items.filter(item => item['value'].toLowerCase().includes(search));
  }

  toggleAllSelection() {
    const options = this.select.options.toArray();
    if (this.allSelected) {
      options.slice(1).forEach((item: MatOption) => item.select());
    } else {
      options.slice(1).forEach((item: MatOption) => item.deselect());
    }
  }

  private filterSelectedItems(selectedItems: SelectDynamicData[] | null): SelectDynamicData[] {
    // Filter out the search option or any invalid items here
    if (!selectedItems) {
      return [];
    }
    return selectedItems.filter(item => item.value !== '' && item.value !== 'search-option');
  }

  getSelectedItemsLabel(): string {
    const selectedItems = this.filterSelectedItems(this.selectedControl.value);
    if (selectedItems.length === 0) {
      return '';
    }
    const firstSelectedItem = selectedItems[0];
    const items = this.items();
    const selectedItem = items ? items.find(item => item.id === firstSelectedItem.id) : null;
    return selectedItem ? selectedItem.value : '';
  }

  onClosed() {
    if (this.onClosedCallback) {
      const selectedItems = this.selectedControl.value;
      this.onClosedCallback(selectedItems);
    }
  }

  compareFn(option: SelectDynamicData, selected: SelectDynamicData): boolean {
    return option && selected ? option.id === selected.id : option === selected;
  }
}
