<br />
<mat-accordion>
  <mat-expansion-panel class="expansion-panel">
    <mat-expansion-panel-header [ngClass]="{'panel-header': isPanelHeaderActive}">
      <mat-panel-title>
        {{ panelTitle() }}
      </mat-panel-title>
    </mat-expansion-panel-header>

    @if (showLeaseActiveControl()) {
    <div class="card-container">
      <mat-card>
        <mat-card-content>
          <mat-label id="lblSelectLease">{{ "lblSelectLeaseDate" | translate}}</mat-label>
          <mat-radio-group [formControl]="selectedLeaseActiveCodeControl" (change)="leaseActiveCodeChange()"
            style="display: flex; flex-direction: column;">
            <mat-radio-button value="0">{{ "lblSelectLeaseAllWithOutstandingPayments" | translate }}</mat-radio-button>
            <mat-radio-button value="1">{{ "lblSelectLeaseActiveWithOutstandingPayments" | translate
              }}</mat-radio-button>
            <mat-radio-button value="2">{{ "lblSelectLeaseInactiveWithOutstandingPayments" | translate
              }}</mat-radio-button>
            <mat-radio-button value="3">{{ "lblSelectLeaseInLitigation" | translate }}</mat-radio-button>
          </mat-radio-group>
        </mat-card-content>
      </mat-card>
    </div>
    }

    @if (showFullLeaseActiveControl()) {
    <div class="card-container">
      <mat-card>
        <mat-card-content>
          <mat-label id="lblSelectLease">{{ "lblSelectLeaseDate" | translate}}</mat-label>
          <mat-radio-group [formControl]="selectedFullLeaseActiveCodeControl" (change)="fullLeaseActiveCodeChange()"
            style="display: flex; flex-direction: column;">
            <mat-radio-button value="0">{{ "lblSelectLeaseActiveAll" | translate }}</mat-radio-button>
            <mat-radio-button value="1">{{ "lblSelectLeaseAllWithOutstandingPayments" | translate }}</mat-radio-button>
            <mat-radio-button value="2">{{ "lblSelectLeaseActiveWithOutstandingPayments" | translate
              }}</mat-radio-button>
            <mat-radio-button value="3">{{ "lblSelectLeaseInactiveWithOutstandingPayments" | translate
              }}</mat-radio-button>
            <mat-radio-button value="4">{{ "lblSelectLeaseInLitigation" | translate }}</mat-radio-button>
          </mat-radio-group>
        </mat-card-content>
      </mat-card>
      <mat-card>
        <mat-card-content>
          <app-multi-select-search-dropdown [items]="leaseTypesListFormatted" [label]="'lblLeaseTypes'"
            [onClosedCallback]="onLeaseTypesListClosed.bind(this)">
          </app-multi-select-search-dropdown>
        </mat-card-content>
      </mat-card>
    </div>
    }

    @if (showBudgetControls() || showPortfolioEvolutionControls()) {
    <div class="container-row">
      @if(showPortfolioEvolutionControls() || showBudgetControls()){
      <mat-form-field class="select-container" appearance="outline">
        <mat-label>{{"lblReferenceYear" | translate}}</mat-label>
        <mat-select [formControl]="selectedRefenceYearControl">
          @for (referenceYear of referenceYears(); track referenceYear) {
          <mat-option [value]="referenceYear" (click)="onReferenceYearSelected(referenceYear)">{{ referenceYear }}
          </mat-option>
          }
        </mat-select>
      </mat-form-field>
      }
      @if(showBudgetControls()){
      <mat-card>
        <mat-card-content>
          <mat-label id="lblTaxeType">{{ "lblTaxeType" | translate}}</mat-label>
          <mat-radio-group [formControl]="selectedTaxTypeCodeControl" (change)="taxTypeCodeChange()"
            style="display: flex; flex-direction: column;">
            <mat-radio-button value="HT">{{ "lblTaxeTypeHT" | translate }}</mat-radio-button>
            <mat-radio-button value="TTC">{{ "lblTaxeTypeTTC" | translate }}</mat-radio-button>
          </mat-radio-group>
        </mat-card-content>
      </mat-card>
      <mat-card>
        <mat-card-content>
          <mat-label id="lblBudgetTypeList">{{ "lblBudgetTypeList" | translate}}</mat-label>
          <mat-radio-group [formControl]="selectedBudgetTypeCodeControl" (change)="budgetTypeCodeChange()"
            style="display: flex; flex-direction: column;">
            <mat-radio-button value="OPEX">{{ "lblBudgetTypeListOpex" | translate }}</mat-radio-button>
            <mat-radio-button value="CAPEX">{{ "lblBudgetTypeListCapex" | translate }}</mat-radio-button>
            <mat-radio-button value="PRODUCT">{{ "lblBudgetTypeListProduct" | translate }}</mat-radio-button>
            <mat-radio-button value="OPEXPRODUCT">{{ "lblBudgetTypeListOpexProduct" | translate }}</mat-radio-button>
            <mat-radio-button value="OPEXCAPEX">{{ "lblBudgetTypeListOpexCapex" | translate }}</mat-radio-button>
          </mat-radio-group>
        </mat-card-content>
      </mat-card>
      }
    </div>
    }

    @if (showRGDControls()) {
    <div class="container-row">
      <mat-card>
        <mat-card-content>
          <mat-label id="lblBudgetType">{{ "lblBudgetType" | translate}}</mat-label>
          <mat-radio-group [formControl]="selectedBudgetTypeCodeRGDControl" (change)="budgetTypeCodeRGDChange()"
            style="display: flex; flex-direction: column;">
            <mat-radio-button value="ALL">{{ "lblBudgetTypeAll" | translate }}</mat-radio-button>
            <mat-radio-button value="OPEX">{{ "lblBudgetTypeOpex" | translate }}</mat-radio-button>
            <mat-radio-button value="CAPEX">{{ "lblBudgetTypeCapex" | translate }}</mat-radio-button>
          </mat-radio-group>
        </mat-card-content>
      </mat-card>
      <mat-card>
        <mat-card-content>
          <mat-label id="lblBudgetStatus">{{ "lblBudgetStatus" | translate}}</mat-label>
          <mat-radio-group [formControl]="selectedBudgetStatusCodeControl" (change)="budgetStatusCodeChange()"
            style="display: flex; flex-direction: column;">
            <mat-radio-button value="ALL">{{ "lblBudgetStatusAll" | translate }}</mat-radio-button>
            <mat-radio-button value="OPEN">{{ "lblBudgetStatusOpen" | translate }}</mat-radio-button>
            <mat-radio-button value="CLOSED">{{ "lblBudgetStatusClosed" | translate }}</mat-radio-button>
          </mat-radio-group>
        </mat-card-content>
      </mat-card>
      <mat-card>
        <mat-card-content>
          <mat-label id="lblExpenseType">{{ "lblExpenseType" | translate}}</mat-label>
          <mat-radio-group [formControl]="selectedExpenseTypeCodeControl" (change)="expenseTypeCodeChange()"
            style="display: flex; flex-direction: column;">
            <mat-radio-button value="ALL">{{ "lblExpenseTypeAll" | translate }}</mat-radio-button>
            <mat-radio-button value="RECOVERABLE">{{ "lblExpenseTypeRecoverable" | translate }}</mat-radio-button>
            <mat-radio-button value="NON_RECOVERABLE">{{ "lblExpenseTypeNonRecoverable" | translate
              }}</mat-radio-button>
          </mat-radio-group>
        </mat-card-content>
      </mat-card>
    </div>
    }

    @if (showLeaseLitigationControl()) {
    <div class="card-container">
      <mat-card>
        <mat-card-content>
          <mat-label id="lblSelectLease">{{ "lblSelectLeaseDate" | translate}}</mat-label>
          <mat-radio-group [formControl]="selectedLeaseLitigationCodeControl" (change)="leaseLitigationCodeChange()"
            style="display: flex; flex-direction: column;">
            <mat-radio-button value="0">{{ "lblSelectAllLeases" | translate }}</mat-radio-button>
            <mat-radio-button value="1">{{ "lblSelectActivesLeases" | translate }}</mat-radio-button>
            <mat-radio-button value="2">{{ "lblSelectInactivesLeases" | translate }}</mat-radio-button>
            <mat-radio-button value="3">{{ "lblSelectInLitigationLeases" | translate }}</mat-radio-button>
          </mat-radio-group>
        </mat-card-content>
      </mat-card>
    </div>
    }

    @if (showUnitTypesControls()) {
    <div class="card-container">
      <mat-card>
        <mat-card-content>
          <app-multi-select-search-dropdown [items]="unitTypeListFormatted" [label]="'lblUnitTypes'"
            [onClosedCallback]="onUnitTypeListClosed.bind(this)">
          </app-multi-select-search-dropdown>
        </mat-card-content>
      </mat-card>

      <mat-card>
        <mat-card-content>
          <app-multi-select-search-dropdown [items]="vacancyTypeListFormatted" [label]="'lblVacancyTypes'"
            [onClosedCallback]="onVacancyTypeListClosed.bind(this)">
          </app-multi-select-search-dropdown>
        </mat-card-content>
      </mat-card>
    </div>
    }

    @if (showVacancyAnalysisControls() || showVacancyPhysicalControls() || showVacancyPhysicalPercentControls() ||
    showVacancyPhysicalFinancialControls()) {
    <div class="card-container">
      <mat-card>
        <mat-card-content>
          <form [formGroup]="vacancyForm" class="auto-width-card">
            <!-- Taux de vacance -->
            <mat-form-field appearance="outline" style="width: 270px;">
              <mat-label>{{ "lblSelectVacancyRateSup" | translate }}</mat-label>
              <input matInput type="number" formControlName="vacancyRate" />
              @if(vacancyControls.vacancyRate.hasError('min')){
              <mat-error>
                {{"lblErrorPositive" | translate}}
              </mat-error>
              }
              @if(vacancyControls.vacancyRate.hasError('required')){
              <mat-error>
                {{"lblAlertRequiredError" | translate}}
              </mat-error>
              }
            </mat-form-field>

            <!-- Surface vacante -->
            <mat-form-field appearance="outline" style="width: 270px;">
              <mat-label>{{ "lblSelectVacancyAreaSup" | translate }}</mat-label>
              <input matInput type="number" formControlName="vacantArea" />
              @if(vacancyControls.vacantArea.hasError('min')){
              <mat-error>
                {{"lblErrorPositive" | translate}}
              </mat-error>
              }
              @if(vacancyControls.vacantArea.hasError('required')){
              <mat-error>
                {{"lblAlertRequiredError" | translate}}
              </mat-error>
              }
            </mat-form-field>

            <!-- Durée de la vacance -->
            <mat-form-field appearance="outline" style="width: 320px;">
              <mat-label>{{ "lblSelectVacancyMonths" | translate }}</mat-label>
              <input matInput type="number" formControlName="vacancyMonths" />
              @if(vacancyControls.vacancyMonths.hasError('min')){
              <mat-error>
                {{"lblErrorPositive" | translate}}
              </mat-error>
              }
              @if (vacancyControls.vacancyMonths.hasError('required')) {
              <mat-error>
                {{ 'lblAlertRequiredError' | translate }}
              </mat-error>
              }
            </mat-form-field>
          </form>
        </mat-card-content>
      </mat-card>
      <mat-card>
        <mat-card-content>
          <app-multi-select-search-dropdown [width]="350" [items]="naturesOfOccupancyListFormatted"
            [label]="'lblNaturesOfOccupancyTitle'" [onClosedCallback]="onNaturesOfOccupancyListClosed.bind(this)">
          </app-multi-select-search-dropdown>
        </mat-card-content>
      </mat-card>
    </div>
    }

    @if(showYieldMonitoringControls()){
      <mat-card>
        <mat-card-content>
          <form [formGroup]="yieldForm" class="auto-width-card">
            <!-- Taux initial facial net >= (%) -->
            <mat-form-field appearance="outline" style="width: 270px;">
              <mat-label>{{ "lblSelectHeadlineNetInitYield" | translate }}</mat-label>
              <input matInput type="number" formControlName="headlineNetInitYield" />
              @if(yieldControls.headlineNetInitYield.hasError('min')){
              <mat-error>
                {{"lblErrorPositive" | translate}}
              </mat-error>
              }
              @if(yieldControls.headlineNetInitYield.hasError('required')){
              <mat-error>
                {{"lblAlertRequiredError" | translate}}
              </mat-error>
              }
            </mat-form-field>

            <!-- Taux initial effectif net >= (%) -->
            <mat-form-field appearance="outline" style="width: 290px;">
              <mat-label>{{ "lblSelectEffectiveNetInitYield" | translate }}</mat-label>
              <input matInput type="number" formControlName="effectiveNetInitYield" />
              @if(yieldControls.effectiveNetInitYield.hasError('min')){
              <mat-error>
                {{"lblErrorPositive" | translate}}
              </mat-error>
              }
              @if(yieldControls.effectiveNetInitYield.hasError('required')){
              <mat-error>
                {{"lblAlertRequiredError" | translate}}
              </mat-error>
              }
            </mat-form-field>
          </form>
        </mat-card-content>
      </mat-card>
    }

    @if (showPropertyValuationControls()) {
      <div class="card-container">
        <mat-card>
          <mat-card-content>
            <mat-form-field appearance="outline">
              <mat-label>{{"lblCutOffDate" | translate}}</mat-label>
              <mat-select [(value)]="selectedCutOffDate" (closed)="onCutOffDateListClosed(selectedCutOffDate())">
                @for (cuttOfDate of cutOffDates; track cuttOfDate) {
                <mat-option [value]="cuttOfDate['formattedDate']">{{ cuttOfDate['formattedDate'] }}
                </mat-option>
                }
              </mat-select>
            </mat-form-field>
          </mat-card-content>
        </mat-card>
        <mat-card>
          <mat-card-content>
            <mat-form-field appearance="outline">
              <mat-label>{{"lblCutOffRevisionDate" | translate}}</mat-label>
              <mat-select [(value)]="selectedCutOffRevisionDate" (closed)="onCutOffRevisionDateListClosed(selectedCutOffRevisionDate())">
                @for (revisionDate of cutOffRevisionDates; track revisionDate) {
                <mat-option [value]="revisionDate['revisionDate']">{{ revisionDate['revisionDate'] }}
                </mat-option>
                }
              </mat-select>
            </mat-form-field>
          </mat-card-content>
        </mat-card>
      </div>
    }
  </mat-expansion-panel>
</mat-accordion>
