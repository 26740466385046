import { HttpClient } from '@angular/common/http';
import { Injectable, computed, inject } from '@angular/core';
import { HttpErrorService } from './http-error.service';
import { DynamicData, Result, ServerSideResponse } from '../model/types';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, catchError, filter, map, of, switchMap } from 'rxjs';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { AuthService } from './auth.service';
import { SidenavService } from './sidenav.service';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root'
})
export class BuildingsInfosService {
  private http = inject(HttpClient);
  private errorService = inject(HttpErrorService);
  private authSvc = inject(AuthService);
  private sidenavService = inject(SidenavService);
  private appService = inject(AppService);

  private loggedUser = this.authSvc.loggedUser;
  private selectedImportDate$ = this.sidenavService.selectedImportDate$; // Observable pour la date d'import
  private selectedPortfolio = this.sidenavService.selectedPortfolio;
  private selectedOwners = this.sidenavService.selectedOwners;
  private selectedGeographicalZones = this.sidenavService.selectedGeographicalZones;
  private selectedBuildingNatures = this.sidenavService.selectedBuildingNatures;

  // Calcul des paramètres pour Buildings Infos en utilisant selectedImportDate
  buildingsInfosParams$ = this.selectedImportDate$.pipe(
    filter(importDate => importDate !== null), // Assurez-vous que la date d'import n'est pas null
    map(importDate => ({
      Filter: {
        SourceTable: "D_BUILDING",
        StartYear: 0,
        EndYear: 0,
        ImportDate: importDate,
        PortfolioCode: this.selectedPortfolio(),
        IdOwnerList: this.selectedOwners().length === 0 ? '-1' : this.selectedOwners().join(','),
        BuildingNatureCodeList: this.selectedBuildingNatures().length === 0 ? "'00000000000000000000'" : this.appService.arrayToString(this.selectedBuildingNatures()),
        GeographicalZonesList: this.selectedGeographicalZones().length === 0 ? "'00000000000000000000'" : this.appService.arrayToString(this.selectedGeographicalZones()),
        IdBuildingList: "-1",
        IdSaleTypeList: "-1",
        IdSaleProgresStateList: "-1",
        User: this.loggedUser()?.UserName,
        TargeCurrency: "EUR",
        CurrencyRateDate: importDate,
        DecryptKey: null,
        LanguageCode: localStorage.getItem('userLocale') || navigator.language,
        PortfolioList: "'" + this.selectedPortfolio() + "'",
        TargetAreaUnitCode: "SQM"
      },
      mappings: null,
      additionalFields: null,
      Format: 0,
      draw: 0,
      columns: null,
      order: null,
      start: 0,
      length: 0,
      search: null,
    }))
  );

  // Observable pour récupérer les informations sur les bâtiments
  private buildingsInfos$ = this.buildingsInfosParams$.pipe(
    switchMap(params =>
      this.http.post<ServerSideResponse>(`${environment.apiUrl}/api/BuildingsInfos/Get`, params).pipe(
        map(response => ({ data: response.data.filter(data => data["BUILDING_LATITUDE"] !== null) } as Result<DynamicData[]>)),
        catchError(err => of({
          data: [],
          error: this.errorService.formatError(err)
        } as Result<DynamicData[]>))
      )
    )
  );

  private buildingsInfosData = toSignal(this.buildingsInfos$, {
    initialValue: ({ data: [] } as Result<DynamicData[]>)
  });

  buildingsInfos = computed(() => this.buildingsInfosData().data);
  buildingsInfosError = computed(() => this.buildingsInfosData().error);

  // Calcul des paramètres pour Key Values en utilisant selectedImportDate
  keyValuesParams$ = this.selectedImportDate$.pipe(
    filter(importDate => importDate !== null), // Assurez-vous que la date d'import n'est pas null
    map(importDate => ({
      Filter: {
        ImportDate: importDate,
        LanguageCode: localStorage.getItem('userLocale') || navigator.language,
        PortfolioList: "'" + this.selectedPortfolio() + "'",
        User: this.loggedUser()?.UserName,
        TargetAreaUnitCode: "SQM",
        TargeCurrency: "EUR",
        CurrencyRateDate: importDate
      },
      mappings: null,
      additionalFields: null,
      Format: 0,
      draw: 0,
      columns: null,
      order: null,
      start: 0,
      length: 0,
      search: null,
    }))
  );

  // Observable pour récupérer les Key Values
  private keyValues$ = this.keyValuesParams$.pipe(
    switchMap(params =>
      this.http.post<ServerSideResponse>(`${environment.apiUrl}/api/KeyValues/Get`, params).pipe(
        map(response => ({ data: response.data } as Result<DynamicData[]>)),
        catchError(err => of({
          data: [],
          error: this.errorService.formatError(err)
        } as Result<DynamicData[]>))
      )
    )
  );

  private keyValuesData = toSignal(this.keyValues$, {
    initialValue: ({ data: [] } as Result<DynamicData[]>)
  });

  keyValues = computed(() => this.keyValuesData().data);
  keyValuesError = computed(() => this.keyValuesData().error);
}
