<div class="confirm-dialog">
  <h3 mat-dialog-title>{{ "Confirmation" | translate }}</h3>
  <div mat-dialog-content>
    <p>{{ data.message | translate }}</p>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-button (click)="onYesClick()">{{ "btnDelete" | translate }}</button>
    <button mat-button (click)="onNoClick()">{{ "btnCancel" | translate }}</button>
  </div>
</div>
