<div class="container">
    <app-card-graph
      [graphTitleCode]="'lblWALBInYears'"
      [graphLayout]="durationToNextBreakLayout"
      [graphData]="durationToNextBreakData"
      [graphError]="occupancyError"
      >
    </app-card-graph>
    <app-card-graph
      [graphTitleCode]="'lblWALTInYears'"
      [graphLayout]="durationToLeaseEndLayout"
      [graphData]="durationToLeaseEndData"
      [graphError]="occupancyError"
      >
    </app-card-graph>
  </div>
