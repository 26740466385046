<div class="container">
    <app-card-graph
      [graphTitleCode]="'lblGrossRentalIncomeEvol'"
      [graphLayout]="rentalIncomeEvolutionLayout"
      [graphData]="rentalIncomeEvolutionData"
      [graphError]="occupancyEvolutionError"
      >
    </app-card-graph>
  </div>

