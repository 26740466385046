<div class="recovery-container">
  <mat-card>
    <mat-card-header>
      <div class="logo">
        <img src="../../../../assets/pl-logo-vert_HD.png" alt="Alhambra Logo">
      </div>
    </mat-card-header>
    <mat-card-title>
      <h2>{{'lblPwdRecoveryLegend' | translate}}</h2>
    </mat-card-title>
    <mat-card-content>
      <form [formGroup]="recoveryForm" (ngSubmit)="onSubmit()">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>{{'lblEmail' | translate}}</mat-label>
          <input matInput type="email" id="email" formControlName="email" placeholder="john@doe.com" />
          <mat-error *ngIf="recoveryForm.get('email')?.invalid && recoveryForm.get('email')?.touched">
            {{'lblInvalidEmail' | translate}}
          </mat-error>
        </mat-form-field>
        <button mat-raised-button color="primary" type="submit" [disabled]="recoveryForm.invalid">{{'btnSubmit' | translate}}</button>
      </form>
    </mat-card-content>
  </mat-card>
</div>
