<div class="container">
  <mat-card class="card-style">
    <mat-card-content>
      <google-map [height]="mapHeight" [width]="mapWidth" [zoom]="mapOptions.zoom!" [options]="mapOptions"
        [center]="mapOptions.center!">
        @for (location of buildingsInfos(); track location) {
        <map-advanced-marker #mapMarker="mapAdvancedMarker"
          [position]="{ lat: location['BUILDING_LATITUDE'], lng: location['BUILDING_LONGITUDE'] }"
          [title]="location['BUILDING_NAME']" (mapClick)="onMarkerClick(mapMarker)">
        </map-advanced-marker>
        <map-info-window>
          <h3>{{ location["BUILDING_NAME"] }}</h3>
          <p>{{ location["ADDRESS1"] }}</p>
          <p>{{ location["POSTAL_CODE"] }} {{ location["CITY"] }}</p>
        </map-info-window>
        }
      </google-map>
      <mat-error *ngIf="buildingsInfosError()">
        {{buildingsInfosError()}}
      </mat-error>
    </mat-card-content>
  </mat-card>

  <mat-card class="card-style">
    <mat-card-content>
      <mat-tab-group class="custom-tab-group">
        @for (portfolio of keyValues(); track portfolio) {
          <mat-tab>
            <ng-template matTabLabel>
              <span class="custom-tab-label">{{ portfolio['portfolio'] }}</span>
            </ng-template>
            <ng-template matTabContent>
              <mat-list>
                <mat-list-item class="custom-list-item">
                  <span matListItemTitle class="custom-list-title">{{ "BUILDING TOTAL AREA" | translate}}</span>
                  <span matListItemLine class="custom-list-text">{{ portfolio['building_total_area_sqm'] | formatDynamic }}</span>
                </mat-list-item>
                <mat-list-item class="custom-list-item">
                  <span matListItemTitle class="custom-list-title">{{ "RENTED_AREA" | translate}}</span>
                  <span matListItemLine class="custom-list-text">{{ portfolio['rented_area'] | formatDynamic }}</span>
                </mat-list-item>
                <mat-list-item class="custom-list-item">
                  <span matListItemTitle class="custom-list-title">{{ "lblAlertVacancyRate" | translate}}</span>
                  <span matListItemLine class="custom-list-text">{{ +portfolio['vacancyrate'] * 100 | formatDynamic }} %</span>
                </mat-list-item>
                <mat-list-item class="custom-list-item">
                  <span matListItemTitle class="custom-list-title">{{ "NUMBER_OF_VACANT_UNITS" | translate}}</span>
                  <span matListItemLine class="custom-list-text">{{ portfolio['number_of_vacant_units'] | formatDynamic }}</span>
                </mat-list-item>
                <mat-list-item class="custom-list-item">
                  <span matListItemTitle class="custom-list-title">{{ "GROSS_HEADLINE_RENT" | translate}}</span>
                  <span matListItemLine class="custom-list-text">{{ portfolio['gross_headline_rent'] | formatDynamic }}</span>
                </mat-list-item>
              </mat-list>

            </ng-template>
        </mat-tab>
        }
      </mat-tab-group>
      <mat-error *ngIf="buildingsInfosError()">
        {{buildingsInfosError()}}
      </mat-error>
    </mat-card-content>
  </mat-card>
</div>

<mat-tab-group>
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="tab-icon">business</mat-icon>
      <mat-label class="title">{{ 'lblPatrimony' | translate }}</mat-label>
    </ng-template>
    <ng-template matTabContent>
      <div class="container">
        <app-card-graph
          [graphTitleCode]="'ASSETS_AREA'"
          [graphLayout]="assetsAreaLayout"
          [graphData]="assetsAreaData"
          [graphError]="chartPortfolioByNatureError"
          >
        </app-card-graph>
        <app-card-graph
          [graphTitleCode]="'BUILDINGS_COUNT'"
          [graphLayout]="buildingsCountLayout"
          [graphData]="buildingsCountData"
          [graphError]="chartPortfolioByNatureError"
          >
        </app-card-graph>
      </div>
    </ng-template>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="tab-icon">groups</mat-icon>
      <mat-label class="title">{{ 'lblOccupancy' | translate }}</mat-label>
    </ng-template>
    <ng-template matTabContent>
      <div class="container">
        <app-card-graph
          [graphTitleCode]="'OCCUPIED_AREA_SQM'"
          [graphLayout]="occupancyAreaLayout"
          [graphData]="occupancyAreaData"
          [graphError]="occupancyError"
          >
        </app-card-graph>
        <app-card-graph
          [graphTitleCode]="'lblPhysicalOccupancySummaryEvolution'"
          [graphLayout]="areaEvolutionLayout"
          [graphData]="areaEvolutionData"
          [graphError]="occupancyError"
          >
        </app-card-graph>
      </div>
    </ng-template>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="tab-icon">trending_up</mat-icon>
      <mat-label class="title">{{ 'lblRentalIncome' | translate }}</mat-label>
    </ng-template>
    <ng-template matTabContent>
      <div class="container">
        <app-card-graph
          [graphTitleCode]="'lblGrossRentalIncomeEvol'"
          [graphLayout]="rentalIncomeEvolutionLayout"
          [graphData]="rentalIncomeEvolutionData"
          [graphError]="occupancyEvolutionError"
          >
        </app-card-graph>
      </div>
    </ng-template>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="tab-icon">paid</mat-icon>
      <mat-label class="title">{{ 'Table3Attr4' | translate }}</mat-label>
    </ng-template>
    <ng-template matTabContent>
      <div class="container">
        <app-card-graph
          [graphTitleCode]="'lblEvolBalanceOutstandPayment'"
          [graphLayout]="waterfallOutstandingPaymentsLayout"
          [graphData]="waterfallOutstandingPaymentsData"
          [graphError]="outstandingPaymentsError"
          >
        </app-card-graph>
      </div>
    </ng-template>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="tab-icon">report_problem</mat-icon>
      <mat-label class="title">{{ 'lblRisk' | translate }}</mat-label>
    </ng-template>
    <ng-template matTabContent>
      <div class="container">
        <app-card-graph
          [graphTitleCode]="'lblWALBInYears'"
          [graphLayout]="durationToNextBreakLayout"
          [graphData]="durationToNextBreakData"
          [graphError]="occupancyError"
          >
        </app-card-graph>
        <app-card-graph
          [graphTitleCode]="'lblWALTInYears'"
          [graphLayout]="durationToLeaseEndLayout"
          [graphData]="durationToLeaseEndData"
          [graphError]="occupancyError"
          >
        </app-card-graph>
      </div>
    </ng-template>
  </mat-tab>

</mat-tab-group>
