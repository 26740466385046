<mat-card>
    <mat-card-header>
      <div class="search-container search-right">
        <mat-form-field appearance="outline">
          <mat-label><mat-icon>search</mat-icon>&nbsp;{{'SEARCH' | translate}}</mat-label>
          <input matInput type="text" (keyup)="applyFilter($event)">
        </mat-form-field>
        <button mat-button (click)="exportToCsv()"><mat-icon>file_download</mat-icon>CSV</button>
      </div>
    </mat-card-header>
    <mat-card-content>
      <div class="mat-elevation-z8 container" [ngClass]="isScrollable ? 'scroll' : '' ">
        @if (!isLoadingResults){
          @if(dataSource.data.length > 0){
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 separator-table" matSort (matSortChange)="SortChange($event)">
              <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column"
                  [sticky]="stickyLeftColumns.includes(column)"
                  [stickyEnd]="stickyRightColumns.includes(column)">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="column-width custom-header-cell"> {{ column | translate | replaceDDMMYYYY: column :analysisDate :startDate }} </th>
                <td mat-cell *matCellDef="let element" class="column-width custom-data-cell">
                  <ng-container *ngIf="column !== 'Actions'"> {{ element[column] | formatDynamic }} </ng-container>
                  <ng-container *ngIf="column === 'Actions'">
                    <div class="action-buttons">
                      <button mat-icon-button (click)="editElement(element)"><mat-icon>edit</mat-icon></button>
                      @if(element["nb_interfaces"] < 1){
                        <button mat-icon-button color="warn" (click)="deleteElement(element)"><mat-icon>delete</mat-icon></button>
                      }
                      <button mat-icon-button (click)="viewElement(element)"><mat-icon>visibility</mat-icon></button>
                    </div>
                  </ng-container>
                </td>
                @if(colSums[column] !== undefined){
                  <td mat-footer-cell *matFooterCellDef> <div class="custom-footer-cell">{{colSums[column] | formatDynamic }}</div> </td>
                }
                @else {
                  <!-- We create a fake footer row if the colsums is not defined -->
                  <td mat-footer-cell *matFooterCellDef></td>
                }
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              @if(hasColSums()){
                <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
              }
            </table>
          }
          @else {
            <h3 class="no-data-message">
              {{ "lblNoDataAvailable" | translate }}
            </h3>
          }
      }
      @else if (isError) {
        <div>
          {{'lblDefaultUserError' | translate}}
        </div>
      }
      @else {
        <div class="spinner-container">
          <mat-spinner [diameter]="200"></mat-spinner>
        </div>
      }
      </div>
    </mat-card-content>
    <mat-card-footer>
      <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" [length]="dataLength"></mat-paginator>
    </mat-card-footer>
</mat-card>
