<mat-form-field appearance="outline" [style.width.px]="width">
  <mat-label>{{label | translate}}</mat-label>
  <mat-select [formControl]="selectedControl" #select (closed)="onClosed()" [compareWith]="compareFn">
    <mat-option disabled>
      <ngx-mat-select-search placeholderLabel="{{ 'SEARCH' | translate }}" noEntriesFoundLabel="{{ 'NO DATA' | translate}}" [formControl]="filterControl">
      </ngx-mat-select-search>
    </mat-option>
      @for (item of (itemsFormatted() | async); track item) {
        <mat-option [value]="item">{{item['value']}}</mat-option>
      }
  </mat-select>
</mat-form-field>
