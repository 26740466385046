<div class="login-container" [ngStyle]="{'background-image': 'url(' + backgroundLogin + ')'}">
  <mat-card>
    <mat-card-header>
      <mat-card-title>{{'lbl-connection' | translate}}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form [formGroup]="loginForm" (ngSubmit)="login()">

        <mat-form-field appearance="outline" style="width: 11rem">
          <mat-label>{{'lbl-language' | translate}}</mat-label>
          <mat-select #select formControlName="language" (selectionChange)="onLocalChanged($event.value)">
          @for (lang of languages; track lang) {
            <mat-option [value]="lang">
              <span class="fi" [ngClass]="{'fi-gb': lang==='en-GB', 'fi-fr': lang==='fr-FR', 'fi-de': lang==='de-DE'}"></span>
              {{ getTranslation(lang) }}
            </mat-option>
          }
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>{{'lbl-username' | translate}}</mat-label>
          <input matInput placeholder="" formControlName="username">
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>{{'lbl-password' | translate}}</mat-label>
          <input matInput [type]="hide ? 'password' : 'text'" placeholder="" formControlName="password">
          <button type="button" mat-icon-button matSuffix (click)="togglePasswordVisibility()">
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
        </mat-form-field>

        <div class="forgot-password">
          <a (click)="navigateToForgotPassword()">{{'linkForgottenPwd' | translate}}</a>
        </div>

        <button mat-raised-button color="primary" type="submit" [disabled]="loginForm.invalid">{{'lbl-login' | translate}}</button>
      </form>
    </mat-card-content>
    <div *ngIf="errorMessage" class="error-message">
      {{ errorMessage }}
    </div>
  </mat-card>
</div>
