import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { PlotlyModule } from 'angular-plotly.js';
import * as PlotlyJS from 'plotly.js-dist-min';
PlotlyModule.plotlyjs = PlotlyJS;

@Component({
  selector: 'app-fullscreen-plot-dialog',
  standalone: true,
  imports: [PlotlyModule, MatDialogModule, MatButtonModule, TranslateModule],
  templateUrl: './fullscreen-plot-dialog.component.html',
  styleUrl: './fullscreen-plot-dialog.component.scss'
})
export class FullscreenPlotDialogComponent implements AfterViewInit {
  @ViewChild('plotContainer', { static: false }) plotContainer!: ElementRef;

  plotlyConfig = {'displaylogo': false};

  constructor(
    public dialogRef: MatDialogRef<FullscreenPlotDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onClose(): void {
    this.dialogRef.close();
  }

  ngAfterViewInit(): void {
    this.updatePlotSize();
  }

  updatePlotSize(): void {
    if (this.plotContainer) {
      const { offsetWidth, offsetHeight } = this.plotContainer.nativeElement;
      this.data.layout.width = offsetWidth;
      this.data.layout.height = offsetHeight;
      PlotlyJS.relayout(this.plotContainer.nativeElement, this.data.layout);
    }
  }
}
