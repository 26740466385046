import { Component, inject } from '@angular/core';
import { GraphService } from '../../shared/service/graph.service';
import { PlotlyModule } from 'angular-plotly.js';
import { CardGraphComponent } from '../card-graph/card-graph.component';
import { SidenavService } from '../../shared/service/sidenav.service';

@Component({
  selector: 'app-income',
  standalone: true,
  imports: [PlotlyModule, CardGraphComponent],
  templateUrl: './income.component.html',
  styleUrl: './income.component.scss'
})
export class IncomeComponent {
  private grahService = inject(GraphService);
  private sidenavService = inject(SidenavService);

  private plotlyGraphDimensions = {
    width: 700,
    height: 400
  };

  plotlyConfig = { displaylogo: false };

  private commonLayout = {
    ...this.plotlyGraphDimensions,
    font: {
      family: 'Roboto',
      size: 16
    }
  };

  // Rental Income Evolution
  rentalIncomeEvolutionLayout = {
    ...this.commonLayout,
    xaxis: {
      type: 'category',
      tickangle: -45,
    },
    hoverlabel: {
      namelength: -1
    }
  };
  rentalIncomeEvolutionData = this.grahService.rentalIncomeEvolutionData;

  occupancyEvolutionError = this.grahService.occupancyEvolutionError;

  ngOnInit(): void {
    this.sidenavService.showSidenav = true;
    this.sidenavService.showBuildingsList.set(false);
  }

}
