import { environment } from "../../../environments/environment";

export class PasswordCharFamily {
  chars: string;
  isUsed: boolean;

  constructor(chars: string) {
    this.chars = chars;
    this.isUsed = false;
  }
}

export class RecoveryInfo {
  usernameToken: TokenData = new TokenData();
  temporaryPassword: string | null = null;
  smsCode: string | null = null;
  date: Date;
  tokenValidtyTime: number;
  tempPasswordValidtyTime: number;
  smsCodeValidtyTime: number;
  successfullyUsed: boolean;

  constructor(userName?: string) {
    this.tokenValidtyTime = environment.tokenValidityTime * 60 * 1000; // 15 minutes in milliseconds
    this.tempPasswordValidtyTime = environment.tempPasswordValidityTime * 60 * 1000; // 15 minutes in milliseconds
    this.smsCodeValidtyTime = environment.smsCodeValidtyTime * 60 * 1000; // 15 minutes in milliseconds

    if (userName) {
      this.setUserNameToken(userName);
      this.date = new Date();
      this.successfullyUsed = false;
    } else {
      this.usernameToken = new TokenData();
      this.temporaryPassword = null;
      this.smsCode = null;
      this.date = new Date();
      this.successfullyUsed = false;
    }
  }

  async setUserNameToken(userName: string): Promise<void> {
    this.usernameToken = await TokenData.createWithHash(userName);
  }
}

export class TokenData {
  saltySHAString: string = '';
  private saltPrefix = 'Please give ';
  private saltSuffix = ' a salty string at';

  constructor() {
  }

  private async generateHash(text: string): Promise<void> {
    this.saltySHAString = await this.getSha256Hash(text);
  }

  private async getSha256Hash(text: string): Promise<string> {
    const encoder = new TextEncoder();
    const data = encoder.encode(text);
    const hashBuffer = await window.crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
  }

  // Public method to create and return a TokenData instance with the hash generated
  public static async createWithHash(userName: string): Promise<TokenData> {
    const tokenData = new TokenData();
    const saltString = `${tokenData.saltPrefix}${userName}${tokenData.saltSuffix}${new Date().getMilliseconds()}`;
    await tokenData.generateHash(saltString);
    return tokenData;
  }
}
