<mat-card class="card-style">
  <mat-card-content>

    <div class="container-column">
      <mat-form-field *ngIf="importDates() as importDates" appearance="outline">
        <mat-label>{{"lblImportDate" | translate}}</mat-label>
        <mat-select [(value)]="selectedDate">
          @for (importDate of importDates; track importDate) {
          <mat-option [value]="importDate['IMPORT_DATE']"
            (click)="onImportDateSelected(importDate['IMPORT_DATE'])">{{importDate['IMPORT_DATE'] | date:
            dateFormat}}</mat-option>
          }
        </mat-select>
      </mat-form-field>
      <mat-error *ngIf="importDatesError()">
        {{importDatesError()}}
      </mat-error>
      <br />

      <div *ngIf="portfoliosList() as portfolios">
        <app-single-select-search-dropdown [items]="portfoliosListFormatted" [label]="'lblPortfolio'"
          [onClosedCallback]="onPortfolioSelected.bind(this)">
        </app-single-select-search-dropdown>
      </div>
      <mat-error *ngIf="portfoliosListError()">
        {{portfoliosListError()}}
      </mat-error>
      <br />

      <!-- Select-multiple-search -->
      <div *ngIf="ownersList() as owners">
        <app-multi-select-search-dropdown [items]="ownersListFormatted" [label]="'lblOwners'"
          [onClosedCallback]="onOwnersListClosed.bind(this)">
        </app-multi-select-search-dropdown>
      </div>
      <mat-error *ngIf="ownersListError()">
        {{ownersListError()}}
      </mat-error>
      <br />

      <!-- Select-multiple-search -->
      <div *ngIf="geographicalZonesList() as geographicalZones">
        <app-multi-select-search-dropdown [items]="geographicalZonesListFormatted" [label]="'lblGeoZones'"
          [onClosedCallback]="onGeographicalZonesListClosed.bind(this)">
        </app-multi-select-search-dropdown>
      </div>
      <mat-error *ngIf="geographicalZonesListError()">
        {{geographicalZonesListError()}}
      </mat-error>
      <br />

      <!-- Select-multiple-search -->
      <div *ngIf="buildingNaturesList() as buildingNatures">
        <app-multi-select-search-dropdown [items]="buildingNaturesListFormatted" [label]="'lblBuildingNatures'"
          [onClosedCallback]="onBuildingNaturesListClosed.bind(this)">
        </app-multi-select-search-dropdown>
      </div>
      <mat-error *ngIf="buildingNaturesListError()">
        {{buildingNaturesListError()}}
      </mat-error>
      <br />

      <!-- Select-multiple-search -->
      <div *ngIf="showBuildingsList() && buildingsList() as buildingsList">
        <app-multi-select-search-dropdown [items]="buildingsListFormatted" [label]="'lblBuildings'"
          [onClosedCallback]="onIdBuildingListSelected.bind(this)">
        </app-multi-select-search-dropdown>
      </div>
      <mat-error *ngIf="showBuildingsList() && buildingsListError()">
        {{buildingsListError()}}
      </mat-error>
      <br />
      <!-- <button *ngIf="showBuildingsList()" mat-raised-button color="primary" (click)="onIdBuildingListSelected()">{{"btnSubmit" | translate}}</button> -->
    </div>

  </mat-card-content>
</mat-card>
