import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDynamic',
  standalone: true
})
export class FormatDynamicPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if (value === null || value === undefined) {
      return value;
    }

    const valueType = typeof value;

    switch (valueType) {
      case 'number':
        return new Intl.NumberFormat().format(value as number);
        case 'string':
          const valueString = value.toString();
          if (this.isISODateStringWithMillis(valueString)) {
            const date = new Date(valueString);
            return this.formatDate(date);
          }
          return value;
      case 'object':
        return this.formatObject(value);
      case 'boolean':
        return value ? 'Oui' : 'Non';
      default:
        return value;
    }
  }

  private isISODateStringWithMillis(value: string): boolean {
    // Check if the string is in the format YYYY-MM-DDTHH:mm:ss.sss, YYYY-MM-DDTHH:mm:ss.ss or YYYY-MM-DDTHH:mm:ss
    return /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,3})?$/.test(value);
  }

  private formatDate(date: Date): string {
    return date.toLocaleDateString();
  }

  private formatObject(obj: unknown): string {
    return JSON.stringify(obj);
  }

}
