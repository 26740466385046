import { Component, OnInit, computed, inject } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DateAdapter, MatNativeDateModule, provideNativeDateAdapter } from '@angular/material/core';
import { TranslateModule } from '@ngx-translate/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReportDatesService } from '../../shared/service/report-dates.service';
import { CommonModule } from '@angular/common';
import { AppService } from '../../shared/service/app.service';

@Component({
  selector: 'app-report-dates',
  standalone: true,
  providers: [provideNativeDateAdapter(), MatDatepickerModule],
  imports: [CommonModule, MatFormFieldModule, MatInputModule, MatDatepickerModule, TranslateModule, FormsModule, ReactiveFormsModule, MatNativeDateModule],
  templateUrl: './report-dates.component.html',
  styleUrl: './report-dates.component.scss'
})
export class ReportDatesComponent implements OnInit {
  private reportDatesService = inject(ReportDatesService);
  private dateAdapter = inject(DateAdapter<any>);
  private appService = inject(AppService);

  private analysisDate = this.reportDatesService.analysisDate;
  private startDate = this.reportDatesService.startDate;
  private endDate = this.reportDatesService.endDate;

  analysisDateControl = computed(() => new FormControl(this.analysisDate()));

  ngOnInit(): void {
    this.dateAdapter.setLocale(localStorage.getItem('userLocale'));
  }

  analysisDateChange() {
    const analysisDate = this.appService.convertToUTCDAte(this.analysisDateControl().value);
    this.reportDatesService.analysisDate.set(analysisDate);
  }

  get showDateRange(): boolean {
    return this.reportDatesService.showDateRange;
  }

  rangeGroup = computed(() =>
    new FormGroup({
      start: new FormControl(this.startDate()),
      end: new FormControl(this.endDate())
    })
  );

  rangeDateChange() {
    const startDate = this.appService.convertToUTCDAte(this.rangeGroup().value?.start);
    const endDate = this.appService.convertToUTCDAte(this.rangeGroup().value?.end);
    if (startDate && endDate) {
      this.reportDatesService.startDate.set(startDate);
      this.reportDatesService.endDate.set(endDate);
    }
  }

  // resetFormDates() {
  //   this.analysisDateControl().setValue(this.reportDatesService.analysisDate());
  //   this.rangeGroup().patchValue({
  //     start: this.reportDatesService.startDate(),
  //     end: this.reportDatesService.endDate()
  //   });
  // }
}
