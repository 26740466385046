import { inject, Injectable } from '@angular/core';
import { TokenResponse } from '../model/types';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  private http = inject(HttpClient);
  private appService = inject(AppService);

  constructor() { }

  generateToken(username: string, password: string, grant_type: string): Observable<TokenResponse>{
    const body = new URLSearchParams();
    body.set('grant_type', 'password');
    body.set('username', username);
    body.set('password', password);

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*'
    });
    return this.http.post<TokenResponse>(`${environment.apiUrl}/token`, body.toString(), {
      headers: headers
    });
  }

  getToken(): TokenResponse | null {
    const tokenString = localStorage.getItem('token');
    if (tokenString) {
      try {
        const token: TokenResponse = JSON.parse(tokenString);
        return token;
      } catch (e) {
        this.appService.error('TokenService - Error parsing token from localStorage :', e);
        return null;
      }
    } else {
      return null;
    }
  }

  isTokenExpired(): boolean {
    const token = this.getToken();
    if (!token) {
      return true;
    }
    else{
      return Date.now() > token.expire_at;
    }
  }

  loadToken(): Promise<boolean> {
    return new Promise((resolve) => {
      const tokenString = localStorage.getItem('token');
      if (tokenString) {
        try {
          resolve(true);
        } catch (e) {
          this.appService.error('TokenService - Error parsing token from localStorage :', e);
          resolve(false);
        }
      } else {
        resolve(false);
      }
    });
  }

  saveToken(token: TokenResponse): void {
    localStorage.setItem('token', JSON.stringify(token));
  }

  clearToken(): void {
    localStorage.removeItem('token');
  }

  waitForToken(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      const checkToken = () => {
        if (this.getToken() && !this.isTokenExpired()) {
          observer.next(true);
          observer.complete();
        } else {
          setTimeout(checkToken, 100);
        }
      };
      checkToken();
    });
  }
}
