import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function PasswordStrengthValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;

        if (!value) {
            return null;
        }

        // Using a specific regular expression
        const passwordRegex = /^.*(?=.{12,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!*@#$%^+=]).*$/;
        const passwordValid = passwordRegex.test(value);

        return !passwordValid ? { passwordStrength: true } : null;
    };
}
