import { ChangeDetectorRef, Component, computed, inject, Input, Signal, ViewChild } from '@angular/core';
import { SelectDynamicData } from '../../shared/model/types';
import { MatOption, MatSelect, MatSelectModule } from '@angular/material/select';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BehaviorSubject, map, ReplaySubject, Subject, takeUntil } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatCheckboxModule } from '@angular/material/checkbox';

@Component({
  selector: 'app-single-select-search-dropdown',
  standalone: true,
  imports: [
    TranslateModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    FormsModule,
    MatIconModule,
    CommonModule,
    NgxMatSelectSearchModule,
    MatCheckboxModule
  ],
  templateUrl: './single-select-search-dropdown.component.html',
  styleUrl: './single-select-search-dropdown.component.scss'
})
export class SingleSelectSearchDropdownComponent {
  @ViewChild('select') select!: MatSelect;

  @Input() items!: Signal<SelectDynamicData[] | undefined>;
  @Input() label!: string;
  @Input() onClosedCallback?: (selectedItems: SelectDynamicData | null) => void;
  @Input() width?: number;


  selectedControl = new FormControl<any | null>(null);
  public filterControl: FormControl = new FormControl();
  public filteredList: ReplaySubject<SelectDynamicData[]> = new ReplaySubject<SelectDynamicData[]>(1);
  allSelected: boolean = false;

  private cdr = inject(ChangeDetectorRef);
  private _onDestroy = new Subject<void>();

  itemsFormatted = computed(() => {
    this.filteredList.next(this.items()!);

    this.filterControl.valueChanges.pipe(
      takeUntil(this._onDestroy),
      map(search => this.filterItems(this.items()!, search))
    ).subscribe(filtered => {
      this.filteredList.next(filtered);
    });
    return this.filteredList;
  });

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  private filterItems(items: SelectDynamicData[], search: string): SelectDynamicData[] {
    if (!search) {
      return items;
    }
    search = search.toLowerCase();
    return items.filter(item => item['value'].toLowerCase().includes(search));
  }

  onClosed() {
    if (this.onClosedCallback) {
      const selectedItems = this.selectedControl.value;
      this.onClosedCallback(selectedItems);
    }
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }
}
