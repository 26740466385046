import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class CustomPaginatorIntl extends MatPaginatorIntl {
  constructor(private translate: TranslateService) {
    super();
    this.getAndInitTranslations();
  }

  getAndInitTranslations() {
    this.translate.onLangChange.subscribe((value) => {
      this.itemsPerPageLabel = this.translate.instant('ITEMS_PER_PAGE');
      this.nextPageLabel = this.translate.instant('NEXT_PAGE');
      this.previousPageLabel = this.translate.instant('PREVIOUS_PAGE');
      this.firstPageLabel = this.translate.instant('FIRST_PAGE');
      this.lastPageLabel = this.translate.instant('LAST_PAGE');
      this.changes.next();
    });
  }

  override getRangeLabel = (page: number, pageSize: number, length: number): string => {
    if (length === 0 || pageSize === 0) {
      return this.translate.instant('RANGE_PAGE_LABEL_ZERO', { length });
    }
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return this.translate.instant('RANGE_PAGE_LABEL', { startIndex: startIndex + 1, endIndex, length });
  }
}
