import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { TokenResponse } from '../model/types';
import { TokenService } from '../service/token.service';
import { AuthService } from '../service/auth.service';

export const tokenInterceptor: HttpInterceptorFn = (req, next) => {
  const tokenSvc = inject(TokenService);
  const authSvc = inject(AuthService);
  const token: TokenResponse | null = tokenSvc.getToken();
  if (token){
    if(tokenSvc.isTokenExpired()){
      authSvc.notifyTokenExpired();
      return next(req.clone({ url: `${req.url}` }));
    }
    else{
      return next(req.clone({
        url: `${req.url}`,
        headers: req.headers.append('Authorization', `Bearer ${token.access_token}`)
      }));
    }
  }
  else{
    return next(req.clone({ url: `${req.url}` }));
  }
};
