<app-navbar *ngIf="showNavigations"></app-navbar>
<mat-sidenav-container class="main-container" [ngClass]="showNavigations ? 'mat-sidenav-container' : ''">
  <mat-sidenav mode="side" class="content" [opened]="showNavigations && showSidenav">
    <app-panel-left *ngIf="showNavigations && showSidenav"></app-panel-left>
  </mat-sidenav>
  <mat-sidenav-content class="main-content"
    [ngClass]="showNavigations ? 'mat-drawer-content' : 'mat-not-drawer-content'"
    [style.margin-left.px]="showNavigations && showSidenav ? 280 : 0"
    [style.margin-right.px]="showNavigations && showSidenav ? 12 : 0">
    <div [ngClass]="showNavigations ? 'router-outlet-container' : ''">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
