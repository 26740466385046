import { AfterViewInit, ChangeDetectorRef, Component, effect, inject, Input, OnChanges, OnDestroy, OnInit, Signal, SimpleChanges, ViewChild } from '@angular/core';
import { DynamicData, ServerSideResponse } from '../shared/model/types';
import { Observable, Subject, takeUntil, catchError, of } from 'rxjs';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { FormatDynamicPipe } from '../shared/pipe/format-dynamic.pipe';
import { MatIconModule } from '@angular/material/icon';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../dialog/confirm-dialog/confirm-dialog.component';
import { ReplaceDDMMYYYYPipe } from '../shared/pipe/replace-ddmmyyyy.pipe';
import { AppService } from '../shared/service/app.service';

@Component({
  selector: 'app-dynamic-table',
  standalone: true,
  imports: [MatButtonModule, MatTableModule, CommonModule, TranslateModule, MatPaginatorModule, MatSortModule, MatCardModule, MatProgressSpinnerModule, MatInputModule, FormsModule, FormatDynamicPipe, MatIconModule, ReplaceDDMMYYYYPipe],
  templateUrl: './dynamic-table.component.html',
  styleUrl: './dynamic-table.component.scss'
})
export class DynamicTableComponent implements OnInit, AfterViewInit, OnDestroy {
  private destroy$ = new Subject<void>();
  @ViewChild(MatSort, { static: false }) set sort(val: MatSort) {
    if (val) {
      this.dataSource.sort = val;
    }
  }
  @ViewChild(MatPaginator, { static: false }) set paginator(val: MatPaginator) {
    if (val) {
      this.dataSource.paginator = val;
    }
  }
  private cdr = inject(ChangeDetectorRef)
  private dialog = inject(MatDialog);
  private appService = inject(AppService);

  dataSource = new MatTableDataSource<DynamicData>;
  displayedColumns: string[] = [];
  dataLength = 0;
  isLoadingResults = true;
  isError = false;
  colSums: DynamicData = {};

  @Input() stickyLeftColumnsCount: number = 0;
  @Input() stickyLeftColumns: string[] = [];
  @Input() showActions?: boolean;
  @Input() isScrollable?: boolean;

  stickyRightColumnsCount: number = 0;
  stickyRightColumns: string[] = [];

  @Input() dataGetter!: (tableParameters: any) => Observable<ServerSideResponse>;
  @Input() editMethod?: (element: DynamicData) => void;
  @Input() deleteMethod?: (element: DynamicData) => Observable<ServerSideResponse>;
  @Input() viewMethod?: (element: DynamicData) => void;
  @Input() analysisDate!: Date | undefined;
  @Input() startDate!: Date | undefined;
  @Input() columns!: Signal<string[]>;

  constructor() {
    effect(() => {
      this.stickyLeftColumns = [];
      this.displayedColumns = this.columns();
      for (let i = 0; i < this.displayedColumns.length && i < this.stickyLeftColumnsCount; i++) {
        this.stickyLeftColumns.push(this.displayedColumns[i]);
      }
      if (this.showActions) {
        this.displayedColumns.push('Actions');
      }
    });
  }

  ngAfterViewInit(): void {
    this.fetchData();
  }


  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  fetchData(): void {
    this.isLoadingResults = true;
    this.dataGetter({}).pipe(
      takeUntil(this.destroy$),
      catchError((error) => {
        this.appService.error('DynamicTableComponent - An error occurs while retrieving data :', error);
        this.isLoadingResults = false;
        this.isError = true;
        return of(null);
      })
    ).subscribe((response: ServerSideResponse | null) => {
      if (response) {
        if (response.data.length > 0 || response.dataType !== "") {
          this.dataSource = new MatTableDataSource(response.data);
          this.dataLength = response.recordsTotal;
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          // We don't apply sums for the footer now
          //this.colSums = response.ColSums;
          this.isLoadingResults = false; // Stop loading when data is valid
        } else {
          // Keep isLoadingResults true since the condition is not met
          this.isLoadingResults = true;
        }
      } else {
        this.isLoadingResults = false; // Stop loading on null response
      }
      this.cdr.detectChanges();
    });
  }

  SortChange(sortState: Sort): void {
    if (sortState.direction) {
      const type = typeof this.dataSource.data[0][sortState.active];
      this.appService.log('DynamicTableComponent - Sort :', `Sorted ${sortState.direction}ending ---- ${sortState.active} as a ${type} type`);
    } else {
      this.appService.log('DynamicTableComponent - Sort :', 'Sorting cleared');
    }
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  hasColSums(): boolean {
    return Object.keys(this.colSums).length > 0;
  }

  editElement(element: DynamicData) {
    if (this.editMethod) {
      this.editMethod(element);
    }
  }

  deleteElement(element: DynamicData) {
    if (this.deleteMethod) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '500px',
        data: { message: 'lblAlertDeleteReportTemplate' }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result && this.deleteMethod) {
          this.deleteMethod(element).subscribe(response => {
            this.appService.log('DynamicTableComponent - Delete report template response :', response);
            this.fetchData();
          });
        }
      });
    }
  }

  viewElement(element: DynamicData) {
    if (this.viewMethod) {
      this.viewMethod(element);
    }
  }

  exportToCsv() {
    if (this.dataSource.filteredData && this.dataSource.filteredData.length > 0) {
      const language = localStorage.getItem('userLocale') || navigator.language;
      this.appService.exportToCsv("export.csv", this.dataSource.filteredData, ";", language, this.displayedColumns);
    }
  }
}
