<div class="container">
    <app-card-graph
      [graphTitleCode]="'OCCUPIED_AREA_SQM'"
      [graphLayout]="occupancyAreaLayout"
      [graphData]="occupancyAreaData"
      [graphError]="occupancyError"
      >
    </app-card-graph>
    <app-card-graph
      [graphTitleCode]="'lblPhysicalOccupancySummaryEvolution'"
      [graphLayout]="areaEvolutionLayout"
      [graphData]="areaEvolutionData"
      [graphError]="occupancyError"
      >
    </app-card-graph>
  </div>
