import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, inject } from '@angular/core';
import * as PlotlyJS from 'plotly.js-dist-min';
import { PlotlyModule } from 'angular-plotly.js';
import { environment } from '../../environments/environment';
import { MatInputModule } from '@angular/material/input';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SidenavService } from '../shared/service/sidenav.service';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { Subject } from 'rxjs';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { TranslateModule } from '@ngx-translate/core';
import { MultiSelectSearchdropdownComponent } from '../select/multi-select-search-dropdown/multi-select-search-dropdown.component';
PlotlyModule.plotlyjs = PlotlyJS;

@Component({
  selector: 'app-chart',
  standalone: true,
  imports: [MatIconModule, MatOptionModule, MatSelectModule, CommonModule, PlotlyModule, FormsModule, ReactiveFormsModule, MatInputModule, MatCheckboxModule, NgxMatSelectSearchModule, TranslateModule, MultiSelectSearchdropdownComponent],
  templateUrl: './test1.component.html',
  styleUrl: './test1.component.scss',
})
export class Test1Component implements OnDestroy, OnInit, AfterViewInit {

  private sidenavService = inject(SidenavService);
  private cdRef = inject(ChangeDetectorRef);
  private _onDestroy = new Subject<void>();

  private http = inject(HttpClient);
  private apiUrl = environment.apiUrl;

  graph3: any;

  ngOnInit(): void {
    this.sidenavService.showSidenav = true;
    this.getChartConcentrationArea();
  }

  ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }


  getChartConcentrationArea(): void {
    const param = {
      Columns: [],
      Request: {
        User: 'alhambra',
        LanguageCode: 'fr-FR',
        ImportDate: '2023-10-31T00:00:00.000Z',
        AnalysisDate: '2023-10-31T00:00:00.000Z',
        PortfolioCode: '00000000000000000000',
        NeutralizationType: 'ALL_BUILDINGS',
        IdOwnerList: '-1',
        GeographicalZoneList: "'00000000000000000000'",
        IdBuildingNatureList: "'00000000000000000000'",
        TargetAreaUnitCode: 'SQM',
        TargetCurrency: 'EUR',
        CurrencyRateDate: '2023-10-31T00:00:00.000Z',
        filter: {
          User: 'alhambra',
          LanguageCode: 'fr-FR',
          ImportDate: '2023-10-31T00:00:00.000Z',
          AnalysisDate: '2023-10-31T00:00:00.000Z',
          PortfolioCode: '00000000000000000000',
          NeutralizationType: 'ALL_BUILDINGS',
          IdOwnerList: '-1',
          GeographicalZoneList: "'00000000000000000000'",
          IdBuildingNatureList: "'00000000000000000000'",
          TargetAreaUnitCode: 'SQM',
          TargetCurrency: 'EUR',
          CurrencyRateDate: '2023-10-31T00:00:00.000Z',
        },
      },
    };

    this.http
      .post(
        `${this.apiUrl}/api/ChartConcentrationArea/ColumnsAsArray`,
        param
      )
      .subscribe((data: any) => {
        this.graph3 = {
          data: [
            {
              type: 'scatterpolar',
              r: data.Result.VALUES,
              theta: data.Result.AXELABELS,
              fill: 'toself',
            },
          ],
          layout: {
            polar: {
              radialaxis: {
                visible: true,
                range: [0, 100],
              },
            },
            title: 'Concentrations Diverses',
          },
        };
      });
  }

}
