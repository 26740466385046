<div class="container">
    <app-card-graph
      [graphTitleCode]="'lblEvolBalanceOutstandPayment'"
      [graphLayout]="waterfallOutstandingPaymentsLayout"
      [graphData]="waterfallOutstandingPaymentsData"
      [graphError]="outstandingPaymentsError"
      >
    </app-card-graph>
  </div>

