import { AfterViewInit, Component, OnInit, computed, effect, inject } from '@angular/core';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { SidenavService } from '../shared/service/sidenav.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { AppService } from '../shared/service/app.service';
import { DynamicData, SelectDynamicData } from '../shared/model/types';
import { MultiSelectSearchdropdownComponent } from '../select/multi-select-search-dropdown/multi-select-search-dropdown.component';
import { SingleSelectSearchDropdownComponent } from '../select/single-select-search-dropdown/single-select-search-dropdown.component';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'app-panel-left',
  standalone: true,
  imports: [MatCardModule, MatOptionModule, MatSelectModule, MatFormFieldModule, MatButtonModule, TranslateModule, CommonModule, FormsModule, ReactiveFormsModule, MultiSelectSearchdropdownComponent, SingleSelectSearchDropdownComponent],
  templateUrl: './panel-left.component.html',
  styleUrl: './panel-left.component.scss'
})
export class PanelLeftComponent implements OnInit {

  private sidenavService = inject(SidenavService);

  selectedDate: string = "";
  dateFormat:string = "dd/MM/yyyy";
  importDates = this.sidenavService.importDates;
  importDatesError = this.sidenavService.importDatesError;

  constructor(){
    effect(() => {
      const importDates = this.importDates();

      if (importDates && importDates.length > 0 && !this.selectedDate) {
        const firstDate = importDates[0]['IMPORT_DATE'];
        this.selectedDate = firstDate;
        this.onImportDateSelected(firstDate);
      }
    }, { allowSignalWrites: true });
  }

  ngOnInit(): void {
    this.dateFormat = localStorage.getItem("dateFormat") != null ? localStorage.getItem("dateFormat")! : "dd/MM/yyyy";
  }

  onImportDateSelected(importDate: string): void {
    const date: Date = new Date(
      Date.UTC(+importDate.substring(0, 4),
        +importDate.substring(5, 7) - 1,
        +importDate.substring(8, 10),
        0,
        0,
        0
      ));
    this.sidenavService.importDateSelected(date);
  }


  portfoliosList = this.sidenavService.portfoliosList;
  portfoliosListError = this.sidenavService.portfoliosListError;
  selectedPortfolio: string = "00000000000000000000";
  portfoliosListFormatted = computed((): SelectDynamicData[] => {
    const dictionary: SelectDynamicData[] = [];
    this.portfoliosList()?.forEach((value, index) => {
      dictionary[index] = { id: value['portfolio_code'], value: value["portfolio_label"] };
    })
    return dictionary;
  });
  onPortfolioSelected(selectedItems: SelectDynamicData | null): void {
    if(selectedItems)
      this.sidenavService.portfolioSelected(selectedItems!.id, selectedItems!.value);
  }


  ownersList = this.sidenavService.ownersList;
  ownersListError = this.sidenavService.ownersListError;
  selectedOwners = new FormControl<number[]>([]);
  ownersListFormatted = computed((): SelectDynamicData[] => {
    const dictionary: SelectDynamicData[] = [];
    this.ownersList()?.forEach((value, index) => {
      dictionary[index] = { id: value["ID_OWNER"], value: value["OWNER_NAME"] };
    })
    return dictionary;
  });

  onOwnersListClosed(selectedItems: SelectDynamicData[] | null): void {
    const selectedValues = selectedItems ? selectedItems.map(item => Number(item.id)) : [];
    this.sidenavService.selectedOwners.set(selectedValues);
  }

  geographicalZonesList = this.sidenavService.geographicalZonesList;
  geographicalZonesListError = this.sidenavService.geographicalZonesListError;
  selectedGeographicalZones = new FormControl<string[]>([]);
  geographicalZonesListFormatted = computed((): SelectDynamicData[] => {
    const dictionary: SelectDynamicData[] = [];
    this.geographicalZonesList()?.forEach((value, index) => {
      dictionary[index] = { id: value["GEOGRAPHICAL_ZONE"], value: value["GEOGRAPHICAL_ZONE"] };
    })
    return dictionary;
  });

  onGeographicalZonesListClosed(selectedItems: SelectDynamicData[] | null): void {
    if(selectedItems){
      const selectedValues = selectedItems ? selectedItems.map(item => item.id) : [];
      this.sidenavService.selectedGeographicalZones.set(selectedValues);
    }
  }

  buildingNaturesList = this.sidenavService.buildingNaturesList;
  buildingNaturesListError = this.sidenavService.buildingNaturesListError;
  selectedBuildingNatures = new FormControl<string[]>([]);
  buildingNaturesListFormatted = computed((): SelectDynamicData[] => {
    const dictionary: SelectDynamicData[] = [];
    this.buildingNaturesList()?.forEach((value, index) => {
      dictionary[index] = { id: value["BUILDING_NATURE_CODE"], value: value["BUILDING_NATURE_LABEL"] };
    })
    return dictionary;
  });

  onBuildingNaturesListClosed(selectedItems: SelectDynamicData[] | null): void {
    if(selectedItems){
      const selectedValues = selectedItems ? selectedItems.map(item => item.id) : [];
      this.sidenavService.selectedBuildingNatures.set(selectedValues);
    }
  }


  showBuildingsList = this.sidenavService.showBuildingsList;
  selectedBuildings = new FormControl<number[]>([]);
  buildingsList = this.sidenavService.buildingsList;
  buildingsListError = this.sidenavService.buildingsListError;

  buildingsListFormatted = computed((): SelectDynamicData[] => {
    const dictionary: SelectDynamicData[] = [];
    this.buildingsList()?.forEach((value, index) => {
      dictionary[index] = { id: value["id_building"], value: value["building_name"] };
    })
    return dictionary;
  });

  onIdBuildingListSelected(selectedItems: SelectDynamicData[] | null): void {
    if(selectedItems){
      const selectedValues = selectedItems ? selectedItems.map(item => Number(item.id)) : [];
      this.sidenavService.selectedIdBuildingList.set(selectedValues);
      const selectedLabels = selectedItems ? selectedItems.map(item => item.value) : [];
      this.sidenavService.selectedBuildingNames.set(selectedLabels);
    }
  }

}
