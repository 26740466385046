import { Injectable, inject, signal } from '@angular/core';
import { Login, User } from '../model/types';
import { Observable, catchError, firstValueFrom, map, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { TokenService } from './token.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PasswordCharFamily, RecoveryInfo } from '../model/baseClasses';
import { MailService } from './mail.service';
import { PasswordRecoveryOption } from '../model/enums';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  private http = inject(HttpClient);
  private router = inject(Router);
  private tokenSvc = inject(TokenService);
  private snackBar = inject(MatSnackBar);
  private mailSvc = inject(MailService);
  private appService = inject(AppService);

  isLoggedIn = signal<boolean | undefined>(this.isLogged());
  loggedUser = signal<User | undefined>(this.getLoggedUser());

  login(username: string, password: string): Observable<User | null> {
    const params: Login = {
      userName: username,
      password: password
    };
    return this.http.post<User>(`${environment.apiUrl}/api/AuthRepository/SignIn`, params).pipe(
      map((user: User) => {
        const isLoggedIn = user.Response === 'Success';
        if(isLoggedIn){
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.loggedUser.set(user);
          if(this.tokenSvc.isTokenExpired()){
            this.tokenSvc.generateToken(username, password, "password").subscribe({
              next: (tokenResponse) => {
                const expire_at = Date.now() + tokenResponse.expires_in * 1000;
                const obj = {
                  ...tokenResponse,
                  expire_at: expire_at
                }
                localStorage.setItem('token', JSON.stringify(obj));
              },
              error: (error) => {
                this.appService.error('AuthService - Generate token - Error :', error);
              }}
            );
          }
        }
        this.isLoggedIn.set(isLoggedIn);
        return isLoggedIn ? user : null;
      }),
      catchError(error => {
        this.isLoggedIn.set(false);
        this.loggedUser.set(undefined);
        return of(null);
      })
    );
  }

  logout() {
    this.isLoggedIn.set(false);
    this.loggedUser.set(undefined);
    localStorage.removeItem('currentUser');
    this.router.navigate(['/login']);
  }

  isLogged(): boolean{
    return !!localStorage.getItem('currentUser');
  }

  getLoggedUser(): User | undefined {
    const userJson = localStorage.getItem('currentUser');
    if (userJson) {
      try {
        return JSON.parse(userJson) as User;
      } catch (e) {
        this.appService.error('AuthService - Error parsing currentUser from localStorage :', e);
        return undefined;
      }
    }
    return undefined;
  }

  notifyTokenExpired() : void {
    this.snackBar.open('(fr) Votre session a expiré. Veuillez vous reconnecter.', '(fr) Fermer', {
      duration: 5000,
    }).afterDismissed().subscribe(() => {
      localStorage.removeItem('token');
      this.logout();
    });
  }

  getUserByUserName(username: string): Observable<User> {
    const params = {
      UserName: username,
    };

    return this.http.post<User>(`${environment.apiUrl}/api/AuthRepository/GetUserByUserName`, params);
  }

  getUserByEmail(email: string): Observable<User> {
    const params = {
      Email: email,
    };

    return this.http.post<User>(`${environment.apiUrl}/api/AuthRepository/GetUserByEmail`, params);
  }

  updateUser(userId: string, userName: string, password: string | null, roleId: string | null, email: string | null, confirmedPassword: string | null): Observable<string>{
    const params = {
      UserId: userId,
      Password: password,
      ConfirmedPassword: confirmedPassword,
      Login: userName,
      RoleId: roleId,
      Email: email
    };

    return this.http.post<string>(`${environment.apiUrl}/api/AuthRepository/UpdateUser`, params);
  }

  checkPassword(userName: string, password: string): Observable<boolean>{
    const params = {
      userName: userName,
      password: password
    }

    return this.http.post<boolean>(`${environment.apiUrl}/api/AuthRepository/CheckPassword`, params);
  }

  updatePassword(userId: string, password: string, confirmedPassword: string): Observable<string>{
    const params = {
      UserId: userId,
      Password: password,
      ConfirmedPassword: confirmedPassword
    }
    return this.http.post<string>(`${environment.apiUrl}/api/AuthRepository/UpdatePassword`, params);
  }

  generateRandomPassword(): string {
    // Every family char is to be applied at least once in the password
    const lowerCases = new PasswordCharFamily('abcdefghijklmnopqursuvwxyz');
    const upperCases = new PasswordCharFamily('ABCDEFGHIJKLMNOPQRSTUVWXYZ');
    const numbers = new PasswordCharFamily('0123456789');
    const specialChars = new PasswordCharFamily('!@£$%^&*()#€');

    const charTypes: PasswordCharFamily[] = [lowerCases, upperCases, numbers, specialChars];
    let charTypesLeft: PasswordCharFamily[] = [];

    const passwordLength = 8;
    let password = '';

    for (let i = 0; i < passwordLength; i++) {
      let charType: PasswordCharFamily;
      let familyIndex: number;

      charTypesLeft = charTypes.filter(ct => !ct.isUsed);

      // Ensure every charType will be present in the password
      const chosenCharTypes = (i >= passwordLength - charTypes.length && charTypesLeft.length > 0)
        ? charTypesLeft
        : charTypes;

      familyIndex = Math.floor(Math.random() * chosenCharTypes.length);
      charType = chosenCharTypes[familyIndex];
      charType.isUsed = true;

      const charIndex = Math.floor(Math.random() * charType.chars.length);
      password += charType.chars.charAt(charIndex);
    }

    return password;
  }

  async recoverPassword(email: string): Promise<boolean> {
    if (this.mailSvc.isValidEmail(email)) {
      try {
        const response = await firstValueFrom(this.getUserByEmail(email));
        const user: User = response;
        if (user) {
          user.RecoveryInfo = new RecoveryInfo(user.UserName);
          await user.RecoveryInfo.setUserNameToken(user.UserName);
          const usernameToken = user.RecoveryInfo.usernameToken.saltySHAString;
          const randomPassword = this.generateRandomPassword();
          // The SMS option is not considered.
          const emailBody = this.mailSvc.getEmailBody(usernameToken, PasswordRecoveryOption.TemporaryPassword, randomPassword);
          this.appService.log("AuthService - Password recovery - Email body :", emailBody)
          // TO DO : Send the mail of recovery password !!!
          return true;
        } else {
          return false;
        }
      } catch (error) {
        this.appService.error('AuthService - Password recovery - Error :', error);
        return false;
      }
    } else {
      return false;
    }
  }
}
