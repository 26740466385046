import { inject, OnInit, ViewChild } from '@angular/core';
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { DynamicTableComponent } from "../dynamic-table/dynamic-table.component";
import * as PlotlyJS from 'plotly.js-dist-min';
import { PlotlyModule } from 'angular-plotly.js';
import { MatCardModule } from '@angular/material/card';
import { GraphService } from '../shared/service/graph.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { GoogleMapsModule, MapAdvancedMarker, MapInfoWindow } from '@angular/google-maps';
import { SidenavService } from '../shared/service/sidenav.service';
import { BuildingsInfosService } from '../shared/service/buildings-infos.service';
import { FullscreenPlotDialogComponent } from '../dialog/fullscreen-plot-dialog/fullscreen-plot-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatListModule } from '@angular/material/list';
import { FormatDynamicPipe } from '../shared/pipe/format-dynamic.pipe';
import { CardGraphComponent } from '../graphs/card-graph/card-graph.component';
PlotlyModule.plotlyjs = PlotlyJS;

@Component({
  selector: 'app-home',
  standalone: true,
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  imports: [
    FormatDynamicPipe,
    MatListModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatFormFieldModule,
    MatTableModule,
    MatTabsModule,
    DynamicTableComponent,
    PlotlyModule,
    CommonModule,
    GoogleMapsModule,
    FullscreenPlotDialogComponent,
    TranslateModule,
    CardGraphComponent
  ]
})
export class HomeComponent implements OnInit {
  private grahService = inject(GraphService);
  private sidenavService = inject(SidenavService);
  private buildingsInfosService = inject(BuildingsInfosService);

  private plotlyGraphWidth: number = 720;
  private plotlyGraphHeight: number = 400;

  plotlyConfig = { 'displaylogo': false };

  private layout = {
    width: this.plotlyGraphWidth,
    height: this.plotlyGraphHeight,
    font: {
      family: 'Roboto',
      size: 16
    }
  };

  // Patrimony
  assetsAreaLayout: any = this.layout;
  assetsAreaData = this.grahService.assetsAreaData;

  buildingsCountLayout: any = this.layout;
  buildingsCountData = this.grahService.buildingsCountData;

  leasesCountLayout: any = this.layout;
  leasesCountData = this.grahService.leasesCountData;

  unitsCountLayout: any = this.layout;
  unitsCountData = this.grahService.unitsCountData;

  grossHeadlineRentLayout: any = this.layout;
  grossHeadlineRentData = this.grahService.grossHeadlineRentData;

  chartPortfolioByNatureError = this.grahService.chartPortfolioByNatureError;

  // Occupancy
  occupancyAreaLayout: any = this.layout;
  occupancyAreaData = this.grahService.occupancyAreaData;

  durationToNextBreakLayout: any = this.layout;
  durationToNextBreakData = this.grahService.durationToNextBreakData;

  durationToLeaseEndLayout: any = this.layout;
  durationToLeaseEndData = this.grahService.durationToLeaseEndData;

  occupancyError = this.grahService.occupancyError;

  // Occupancy evolution
  areaEvolutionLayout: any = {
    barmode: 'stack',
    xaxis: {
      type: 'category',
      tickangle: -45,
    },
    width: this.plotlyGraphWidth,
    height: this.plotlyGraphHeight,
    hoverlabel: {
      namelength: -1
    },
    font: {
      family: 'Roboto',
      size: 16
    }
  };
  areaEvolutionData = this.grahService.areaEvolutionData;

  rentalIncomeEvolutionLayout: any = {
    xaxis: {
      type: 'category',
      tickangle: -45,
    },
    width: this.plotlyGraphWidth,
    height: this.plotlyGraphHeight,
    hoverlabel: {
      namelength: -1
    },
    font: {
      family: 'Roboto',
      size: 16
    }
  };
  rentalIncomeEvolutionData = this.grahService.rentalIncomeEvolutionData;

  occupancyEvolutionError = this.grahService.occupancyEvolutionError;

  // Outstanding Payments
  waterfallOutstandingPaymentsLayout: any = this.layout;
  waterfallOutstandingPaymentsData = this.grahService.waterfallOutstandingPaymentsData;

  outstandingPaymentsError = this.grahService.outstandingPaymentsError;


  ngOnInit(): void {
    this.sidenavService.showSidenav = true;
    this.sidenavService.showBuildingsList.set(false);
    this.getCurrentPosition();
  }

  keyValues = this.buildingsInfosService.keyValues;
  keyValuesError = this.buildingsInfosService.keyValuesError;

  // Google Maps
  @ViewChild(MapInfoWindow) infoWindow!: MapInfoWindow;

  buildingsInfos = this.buildingsInfosService.buildingsInfos;
  buildingsInfosError = this.buildingsInfosService.buildingsInfosError;

  mapHeight: string = '370px';
  mapWidth: string = '720px';

  mapOptions: google.maps.MapOptions = {
    center: {
      lat: 48.8583,
      lng: 2.2944
    },
    mapId: 'customMap',
    scrollwheel: false,
    disableDoubleClickZoom: true,
    mapTypeId: 'roadmap',
    zoom: 6,
    maxZoom: 20,
    minZoom: 2,
  };

  getCurrentPosition(): void {
    navigator.geolocation.getCurrentPosition((position) => {
      this.mapOptions.center = {
        lat: position?.coords.latitude ?? 48.8583,
        lng: position?.coords.longitude ?? 2.2944,
      }
    });
  }

  onMarkerClick(marker: MapAdvancedMarker) {
    this.infoWindow.open(marker);
  }

  // addMarker() {
  //   this.markers.push({
  //     position: {
  //       lat: this.center.lat + ((Math.random() - 0.5) * 2) / 10,
  //       lng: this.center.lng + ((Math.random() - 0.5) * 2) / 10,
  //     },
  //     label: {
  //       color: 'red',
  //       text: 'Marker label ' + (this.markers.length + 1),
  //     },
  //     title: 'Marker title ' + (this.markers.length + 1),
  //     info: 'Marker info ' + (this.markers.length + 1),
  //     options: {
  //       animation: google.maps.Animation.BOUNCE,
  //     },
  //   });
  // }

}
