import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReportDatesService {

  analysisDate = signal<Date | undefined>(undefined);
  startDate = signal<Date | undefined>(undefined);
  endDate = signal<Date | undefined>(undefined);

  showDateRange: boolean = false;
}
