<div class="container">
    <app-card-graph
      [graphTitleCode]="'ASSETS_AREA'"
      [graphLayout]="assetsAreaLayout"
      [graphData]="assetsAreaData"
      [graphError]="chartPortfolioByNatureError"
      >
    </app-card-graph>
    <app-card-graph
      [graphTitleCode]="'BUILDINGS_COUNT'"
      [graphLayout]="buildingsCountLayout"
      [graphData]="buildingsCountData"
      [graphError]="chartPortfolioByNatureError"
      >
    </app-card-graph>
    <app-card-graph
      [graphTitleCode]="'LEASES_COUNT'"
      [graphLayout]="leasesCountLayout"
      [graphData]="leasesCountData"
      [graphError]="chartPortfolioByNatureError"
      >
    </app-card-graph>
    <app-card-graph
      [graphTitleCode]="'UNITS_COUNT'"
      [graphLayout]="unitsCountLayout"
      [graphData]="unitsCountData"
      [graphError]="chartPortfolioByNatureError"
      >
    </app-card-graph>
    <app-card-graph
      [graphTitleCode]="'GROSS_HEADLINE_RENT'"
      [graphLayout]="grossHeadlineRentLayout"
      [graphData]="grossHeadlineRentData"
      [graphError]="chartPortfolioByNatureError"
      >
    </app-card-graph>
  </div>

