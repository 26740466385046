import { Component, inject, OnInit, signal, ViewChild } from '@angular/core';
import { ModelReportService } from '../../shared/service/model-report.service';
import { AuthService } from '../../shared/service/auth.service';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { DynamicData, ServerSideResponse } from '../../shared/model/types';
import { TranslateModule } from '@ngx-translate/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { CommonModule } from '@angular/common';
import { FormatDynamicPipe } from '../../shared/pipe/format-dynamic.pipe';
import { DynamicTableComponent } from '../../dynamic-table/dynamic-table.component';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { SidenavService } from '../../shared/service/sidenav.service';

@Component({
  selector: 'app-manage-custom-report',
  standalone: true,
  imports: [ DynamicTableComponent, MatCardModule, MatProgressSpinnerModule, MatInputModule, MatTableModule, MatIconModule, TranslateModule, MatPaginatorModule, CommonModule, FormatDynamicPipe],
  templateUrl: './manage-custom-report.component.html',
  styleUrl: './manage-custom-report.component.scss'
})
export class ManageCustomReportComponent implements OnInit{
  private modelReportService = inject(ModelReportService);
  private authService = inject(AuthService);
  private router = inject(Router);
  private sidenavService = inject(SidenavService);

  columnsToDisplay = signal<string[]>(["template_name", "report_name", "share_with_other_users", "updated_at", "nb_interfaces"]);

  ngOnInit(): void {
    this.sidenavService.showSidenav = false;
  }

  getReportTemplates = (): Observable<ServerSideResponse> => {
    return this.modelReportService.getReportTemplates(null, this.authService.getLoggedUser()?.UserName!);
  }

  editFunction = (element: DynamicData): void => {
    const idReportTemplate = element['id_report_template'];
    const reportCode = element['report_code'];

    const url = `/model/${idReportTemplate}/${reportCode}`;
    this.router.navigateByUrl(url);
  }

  deleteFunction = (element: DynamicData) : Observable<ServerSideResponse> => {
    return this.modelReportService.deleteReportTemplate(element["id_report_template"]);
  }

  viewFunction = (element: DynamicData): void => {
    const reportCode = element['report_code'];
    const idReportTemplate = element['id_report_template'];

    const url = `reports/report/${reportCode}/${idReportTemplate}`;
    this.router.navigateByUrl(url);
  }
}
