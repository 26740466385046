<div mat-dialog-content class="dialog-content">
  <div #plotContainer class="plot-container">
    <div class="rounded">
      <plotly-plot [data]="data.plotData" [layout]="data.layout" [useResizeHandler]="true"
        [config]="plotlyConfig"></plotly-plot>
    </div>
  </div>
</div>
<div mat-dialog-actions class="dialog-content" align="end">
  <button mat-button mat-dialog-close (click)="onClose()">{{'btnClose' | translate}}</button>
</div>