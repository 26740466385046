<mat-card style="margin-top: 20px; margin-bottom: 20px;">
    <mat-card-header style="margin-bottom: 10px;">
        <mat-card-title>Paramètres des requêtes</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-label>Date d'import : {{reportParams.filter.ImportDate.substring(0, 10)}} - Date d'analyse : {{reportParams.filter.AnalysisDate.substring(0, 10)}} - Immeubles : {{reportParams.filter.BuildingsNames}}</mat-label>
    </mat-card-content>
</mat-card>
<button mat-raised-button color="primary" style="margin-bottom: 10px;" (click)="clearServerCache()">Effacer cache serveur</button>

<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> Id </th>
        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>

    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Nom </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <ng-container matColumnDef="controller">
        <th mat-header-cell *matHeaderCellDef> Contrôleur </th>
        <td mat-cell *matCellDef="let element"> {{element.controller}} </td>
    </ng-container>

    <ng-container matColumnDef="expectedResult">
        <th mat-header-cell *matHeaderCellDef> Nb lignes attendues </th>
        <td mat-cell *matCellDef="let element"> {{element.expectedResult}} </td>
    </ng-container>

    <ng-container matColumnDef="result">
        <th mat-header-cell *matHeaderCellDef> Nb lignes </th>
        <td mat-cell *matCellDef="let element"> {{element.result}} </td>
    </ng-container>

    <ng-container matColumnDef="elapsedTime">
        <th mat-header-cell *matHeaderCellDef> Temps passé (s) </th>
        <td mat-cell *matCellDef="let element"> {{element.elapsedTime}} </td>
    </ng-container>

    <ng-container matColumnDef="test">
        <th mat-header-cell *matHeaderCellDef> Tester </th>
        <td mat-cell *matCellDef="let element">
            <button mat-raised-button color="primary"
                (click)="launchTests(element.id, element.controller)">Tester</button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>