<mat-drawer-container>
    <mat-drawer mode="side" position="end" opened style="width: 50%;">
        <mat-card class="card-style">
            <mat-card-header>
                <mat-card-title>
                    <mat-label>{{ 'lblLeaseRepositoryImportsList' | translate }}</mat-label>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
            </mat-card-content>
        </mat-card>
        <div class="dynamic-table">
            <app-dynamic-table
                [dataGetter]="getFileImportData"
                [columns]="columnsToDisplay"
                [analysisDate]="analysisDate()"
                [startDate]="startDate()"
                [stickyLeftColumnsCount]="stickyLeftColumnsCount"
                [stickyLeftColumns]="stickyLeftColumns"
                [isScrollable]="false">
            </app-dynamic-table>
        </div>
    </mat-drawer>
    <mat-drawer-content style="width: 50%;">
        <mat-card class="card-style">
            <mat-card-header>
                <mat-card-title>
                    <mat-label>{{ 'lblNewLeaseRepositoryImport' | translate }}</mat-label>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <p>{{ txtMenuLeaseRepositoryPart1 }}</p>
                <p>{{ txtMenuLeaseRepositoryPart2 }}</p>
            </mat-card-content>
        </mat-card>

        <mat-card class="card-style">
            <mat-card-content>
                <p><mat-checkbox [formControl]="cbLeaseRepositoryControl" class="checkbox-download">{{
                        'cbLeaseRepositoryDownload' | translate }}</mat-checkbox></p>
                <button mat-raised-button (click)="downloadTemplate()">{{ 'btnLeaseRepositoryDownload' | translate
                    }}</button>
                <p>{{ 'txtModelFileNetBookValue' | translate }}</p>
            </mat-card-content>
        </mat-card>

        <mat-card class="card-style">
            <mat-card-content>
                <div (click)="uploadFile.click()">
                    <button mat-raised-button>{{ 'btnUpload' | translate }}</button>
                    <input #uploadFile (change)="upload($event)" type='file' style="display:none" accept=".csv"/>
                </div>
                <p>{{ txtModelImportFilePart1 }}</p>
                <p>{{ txtModelImportFilePart2 }}</p>
                <p>{{ txtModelImportFilePart3 }}</p>
            </mat-card-content>
        </mat-card>

    </mat-drawer-content>
</mat-drawer-container>
