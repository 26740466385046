import { Component, computed, effect, inject, OnInit, signal } from '@angular/core';
import { SpecificCriteriaService } from '../../shared/service/specific-criteria.service';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRadioModule } from '@angular/material/radio';
import { TranslateModule } from '@ngx-translate/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { ReportService } from '../../shared/service/report.service';
import { MatSelectModule } from '@angular/material/select';
import { SidenavService } from '../../shared/service/sidenav.service';
import { AppService } from '../../shared/service/app.service';
import { filter, tap } from 'rxjs';
import { DynamicData, SelectDynamicData } from '../../shared/model/types';
import { MultiSelectSearchdropdownComponent } from '../../select/multi-select-search-dropdown/multi-select-search-dropdown.component';
import { CommonModule, DatePipe } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { SingleSelectSearchDropdownComponent } from '../../select/single-select-search-dropdown/single-select-search-dropdown.component';

@Component({
  selector: 'app-specific-criteria',
  standalone: true,
  imports: [
    MatExpansionModule,
    MatRadioModule,
    MatSelectModule,
    TranslateModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatCardModule,
    MultiSelectSearchdropdownComponent,
    SingleSelectSearchDropdownComponent,
    CommonModule,
    MatInputModule
  ],
  providers: [DatePipe],
  templateUrl: './specific-criteria.component.html',
  styleUrl: './specific-criteria.component.scss',
})
export class SpecificCriteriaComponent implements OnInit {
  constructor(){
    effect(() => {
      this.specificCriteriaService.getCutOffDateList().subscribe((result) => {
        if(result.data.length > 0) {
          result.data.forEach((value, index) => {
            const formattedDate = this.datePipe.transform(value['cut_off_date'], this.dateFormat);
            this.cutOffDates[index] = { formattedDate };
          });
          if (this.cutOffDates.length > 0) {
            this.selectedCutOffDate.set(this.cutOffDates[0]['formattedDate']);
            this.onCutOffDateListClosed(this.selectedCutOffDate());
            this.specificCriteriaService.getCutOffRevisDateList(this.selectedCutOffDate()!).subscribe((revisDate) => {
              revisDate.data.forEach((value, index) => {
                const revisionDate = this.datePipe.transform(value['revision_date'], this.dateFormat);
                this.cutOffRevisionDates[index] = { revisionDate };
              });
              if(this.cutOffRevisionDates.length > 0){
                this.selectedCutOffRevisionDate.set(this.cutOffRevisionDates[0]['revisionDate']);
                this.onCutOffRevisionDateListClosed(this.selectedCutOffRevisionDate());
              }
            });
          }
        }
        else{
          this.selectedCutOffDate.set(null);
          this.selectedCutOffRevisionDate.set(null);
          this.cutOffRevisionDates = [];
          this.cutOffDates = [];
        }
      });
    });
  }

  selectedCutOffDate = signal<string | null>(null);
  selectedCutOffRevisionDate = signal<string | null>(null);
  cutOffRevisionDates: DynamicData[] = [];
  cutOffDates: DynamicData[] = [];

  private specificCriteriaService = inject(SpecificCriteriaService);
  private reportService = inject(ReportService);
  private sidenavService = inject(SidenavService);
  private appService = inject(AppService);
  private datePipe = inject(DatePipe);

  isPanelHeaderActive: boolean = false;
  dateFormat: string = "dd/MM/yyyy";

  vacancyForm = new FormGroup({
    vacancyRate: new FormControl(0, [Validators.required, Validators.min(0)]),
    vacantArea: new FormControl(0, [Validators.required, Validators.min(0)]),
    vacancyMonths: new FormControl(0, [Validators.required, Validators.min(0)]),
  });

  yieldForm = new FormGroup({
    headlineNetInitYield: new FormControl(0, [Validators.required, Validators.min(0)]),
    effectiveNetInitYield: new FormControl(0, [Validators.required, Validators.min(0)])
  });

  ngOnInit(): void {
    this.dateFormat = localStorage.getItem("dateFormat") != null ? localStorage.getItem("dateFormat")! : "dd/MM/yyyy";
    this.subscribeToFormValueChanges(this.vacancyForm, 'vacancyRate', this.specificCriteriaService.selectedVacancyRate);
    this.subscribeToFormValueChanges(this.vacancyForm, 'vacantArea', this.specificCriteriaService.selectedVacancyArea);
    this.subscribeToFormValueChanges(this.vacancyForm, 'vacancyMonths', this.specificCriteriaService.selectedVacancyMonths);
    this.subscribeToFormValueChanges(this.yieldForm, 'headlineNetInitYield', this.specificCriteriaService.selectedHeadlineNetInitYield);
    this.subscribeToFormValueChanges(this.yieldForm, 'effectiveNetInitYield', this.specificCriteriaService.selectedEffectiveNetInitYield);
  }

  private subscribeToFormValueChanges(form: FormGroup, controlName: string, serviceProperty: { set: (value: string) => void }): void {
    form.get(controlName)?.valueChanges.subscribe(value => {
      if (value !== null && value !== undefined && value >= 0) {
        serviceProperty.set(value.toString());
      }
    });
  }

  get vacancyControls() {
    return this.vacancyForm.controls;
  }

  get yieldControls() {
    return this.yieldForm.controls;
  }

  showLeaseActiveControl = computed(() => {
    if (
      this.reportService.reportController().indexOf('OutstandingPayments') >
      -1 ||
      this.reportService.reportController().indexOf('OutstandPayInv') > -1
    ) {
      return true;
    } else {
      return false;
    }
  });

  selectedLeaseActiveCodeControl = new FormControl(
    this.specificCriteriaService.selectedLeaseActiveCode()
  );

  leaseActiveCodeChange() {
    this.specificCriteriaService.selectedLeaseActiveCode.set(
      this.selectedLeaseActiveCodeControl.value
    );
  }

  showFullLeaseActiveControl = computed(() => {
    if (
      this.reportService.reportController().indexOf('OutstandPayMonitoring') >
      -1
    ) {
      return true;
    } else {
      return false;
    }
  });

  selectedFullLeaseActiveCodeControl = new FormControl(
    this.specificCriteriaService.selectedFullLeaseActiveCode()
  );

  fullLeaseActiveCodeChange() {
    this.specificCriteriaService.selectedFullLeaseActiveCode.set(
      this.selectedFullLeaseActiveCodeControl.value
    );
  }

  showBudgetControls = computed(() => {
    if (this.reportService.reportController().indexOf('Budget') > -1) {
      return true;
    } else {
      return false;
    }
  });

  selectedRefenceYearControl = new FormControl(
    this.specificCriteriaService.selectedReferenceYear()
  );

  referenceYears = computed(() => {
    let years: number[] = [];
    this.sidenavService.selectedImportDate$
      .pipe(
        filter((importDate) => importDate !== null),
        tap((importDate) => {
          const startYear = importDate?.getFullYear();
          if (startYear) {
            for (let i = startYear; i > startYear - 10; i--) {
              const found = years.find((element) => element === i);
              if (found === undefined) {
                years.push(i);
              }
            }
            this.selectedRefenceYearControl.setValue(startYear);
          }
        })
      )
      .subscribe();
    return years;
  });

  showUnitTypesControls = computed(() => {
    if (this.reportService.reportController().indexOf('VacantUnits') > -1) {
      return true;
    } else {
      return false;
    }
  });

  unitTypeListFormatted = computed((): SelectDynamicData[] => {
    const dictionary: SelectDynamicData[] = [];
    this.specificCriteriaService.getUnitTypeList().subscribe((result) => {
      result.data.forEach((value, index) => {
        dictionary[index] = {
          id: "'" + value['unit_type_code'] + "'",
          value: value['unit_type_label'],
        };
      });
    });
    return dictionary;
  });
  onUnitTypeListClosed(selectedItems: SelectDynamicData[] | null): void {
    if (selectedItems)
      this.specificCriteriaService.selectedUnitTypeListCode.set(selectedItems);
  }

  vacancyTypeListFormatted = computed((): SelectDynamicData[] => {
    const dictionary: SelectDynamicData[] = [];
    this.specificCriteriaService.getVacancyTypeList().subscribe((result) => {
      result.data.forEach((value, index) => {
        dictionary[index] = {
          id: "'" + value['vacancy_type_code'] + "'",
          value: value['vacancy_type_label'],
        };
      });
    });
    return dictionary;
  });
  onVacancyTypeListClosed(selectedItems: SelectDynamicData[] | null): void {
    if (selectedItems)
      this.specificCriteriaService.selectedVacancyTypeListCode.set(
        selectedItems
      );
  }

  showVacancyAnalysisControls = computed(() => {
    if (this.reportService.reportController().indexOf('VacancyAnalysis') > -1) {
      return true;
    } else {
      return false;
    }
  });
  naturesOfOccupancyListFormatted = computed((): SelectDynamicData[] => {
    const dictionary: SelectDynamicData[] = [];
    this.specificCriteriaService.getNaturesOfOccupancyList().subscribe((result) => {
      result.data.forEach((value, index) => {
        dictionary[index] = {
          id: "'" + value['nature_of_occupancy_code'] + "'",
          value: value['nature_of_occupancy_label'],
        };
      });
    });
    return dictionary;
  });
  onNaturesOfOccupancyListClosed(selectedItems: SelectDynamicData[] | null): void {
    if (selectedItems)
      this.specificCriteriaService.selectedNaturesOfOccupancy.set(selectedItems);
  }


  leaseTypesListFormatted = computed((): SelectDynamicData[] => {
    const dictionary: SelectDynamicData[] = [];
    this.specificCriteriaService.getLeaseTypesList().subscribe((result) => {
      result.data.forEach((value, index) => {
        dictionary[index] = {
          id: "'" + value['lease_type_code'] + "'",
          value: value['lease_type_label'],
        };
      });
    });
    return dictionary;
  });
  onLeaseTypesListClosed(selectedItems: SelectDynamicData[] | null): void {
    if (selectedItems)
      this.specificCriteriaService.selectedLeaseTypesListCode.set(selectedItems);
  }

  showVacancyPhysicalControls = computed(() => {
    if (this.reportService.reportController().indexOf('VacancyPhysical') > -1) {
      return true;
    } else {
      return false;
    }
  });

  showVacancyPhysicalPercentControls = computed(() => {
    if (this.reportService.reportController().indexOf('VacancyPhysicalPercent') > -1) {
      return true;
    } else {
      return false;
    }
  });

  showVacancyPhysicalFinancialControls = computed(() => {
    if (this.reportService.reportController().indexOf('VacancyPhysicalFinancial') > -1) {
      return true;
    } else {
      return false;
    }
  });

  showPortfolioEvolutionControls = computed(() => {
    if (this.reportService.reportController().indexOf('PortfolioEvolution') > -1) {
      return true;
    } else {
      return false;
    }
  });

  showYieldMonitoringControls = computed(() => {
    if (this.reportService.reportController().indexOf('YieldMonitoring') > -1) {
      return true;
    } else {
      return false;
    }
  });

  showPropertyValuationControls = computed(() => {
    if (this.reportService.reportController().indexOf('PropertyValuation') > -1) {
      return true;
    } else {
      return false;
    }
  });

  onCutOffDateListClosed(selectedItem: string | null): void {
    if (selectedItem){
      const convertedDate = this.appService.convertStringToUTCDate(selectedItem);
      this.specificCriteriaService.selectedCutOffDate.set(convertedDate);
    }
  }
  onCutOffRevisionDateListClosed(selectedItem: string | null): void {
    if (selectedItem){
      const convertedDate = this.appService.convertStringToUTCDate(selectedItem);
      this.specificCriteriaService.selectedCutOffRevisionDate.set(convertedDate);
    }
  }

  onReferenceYearSelected(year: number) {
    this.specificCriteriaService.selectedReferenceYear.set(year);
  }

  selectedBudgetTypeCodeControl = new FormControl(
    this.specificCriteriaService.selectedBudgetTypeCode()
  );

  budgetTypeCodeChange() {
    this.specificCriteriaService.selectedBudgetTypeCode.set(
      this.selectedBudgetTypeCodeControl.value
    );
  }

  selectedTaxTypeCodeControl = new FormControl(
    this.specificCriteriaService.selectedTaxTypeCode()
  );

  taxTypeCodeChange() {
    this.specificCriteriaService.selectedTaxTypeCode.set(
      this.selectedTaxTypeCodeControl.value
    );
  }

  showLeaseLitigationControl = computed(() => {
    if (this.reportService.reportController().indexOf('Security') > -1) {
      return true;
    } else {
      return false;
    }
  });

  selectedLeaseLitigationCodeControl = new FormControl(
    this.specificCriteriaService.selectedLeaseLitigationCode()
  );

  leaseLitigationCodeChange() {
    this.specificCriteriaService.selectedLeaseLitigationCode.set(
      this.selectedLeaseLitigationCodeControl.value
    );
  }

  showRGDControls = computed(() => {
    if (this.reportService.reportController().indexOf('General') > -1) {
      return true;
    } else {
      return false;
    }
  });

  selectedBudgetTypeCodeRGDControl = new FormControl(
    this.specificCriteriaService.selectedBudgetTypeCodeRGD()
  );

  budgetTypeCodeRGDChange() {
    this.specificCriteriaService.selectedBudgetTypeCodeRGD.set(
      this.selectedBudgetTypeCodeRGDControl.value
    );
  }

  selectedBudgetStatusCodeControl = new FormControl(
    this.specificCriteriaService.selectedBudgetStatusCode()
  );

  budgetStatusCodeChange() {
    this.specificCriteriaService.selectedBudgetStatusCode.set(
      this.selectedBudgetStatusCodeControl.value
    );
  }

  selectedExpenseTypeCodeControl = new FormControl(
    this.specificCriteriaService.selectedExpenseTypeCode()
  );

  expenseTypeCodeChange() {
    this.specificCriteriaService.selectedExpenseTypeCode.set(
      this.selectedExpenseTypeCodeControl.value
    );
  }

  panelTitle = computed(() => {
    this.isPanelHeaderActive = false;
    const language = localStorage.getItem('userLocale') || navigator.language;
    let title = `${this.appService.getTranslation(
      language,
      'lblSelectedParams'
    )} : `;

    if (this.showLeaseActiveControl()) {
      title += `${this.appService.getTranslation(
        language,
        'lblSelectLeaseDate'
      )} = ${this.specificCriteriaService.getLeaseActiveLabel(
        this.specificCriteriaService.selectedLeaseActiveCode()
      )}`;
    }

    if (this.showFullLeaseActiveControl()) {
      const formattedLeaseTypes = this.specificCriteriaService
        .selectedLeaseTypesListCode()
        .map((item: SelectDynamicData) => item.value)
        .join(', ');
      title += `${this.appService.getTranslation(language, 'lblSelectLeaseDate')} = ${this.specificCriteriaService.getFullLeaseActiveLabel(this.specificCriteriaService.selectedFullLeaseActiveCode())} `
        + `/ ${this.appService.getTranslation(language, 'lblLeaseTypes')} = `
        + (formattedLeaseTypes.length > 0 ? `${formattedLeaseTypes}` : `${this.appService.getTranslation(
          language,
          'NO_FILTER'
        )}`);
    }

    if (this.showBudgetControls()) {
      title +=
        `${this.appService.getTranslation(
          language,
          'lblReferenceYear'
        )} = ${this.specificCriteriaService
          .selectedReferenceYear()
          ?.toString()} / ` +
        `${this.appService.getTranslation(
          language,
          'lblTaxeType'
        )} = ${this.specificCriteriaService.getTaxTypeLabel(
          this.specificCriteriaService.selectedTaxTypeCode()
        )} / ` +
        `${this.appService.getTranslation(
          language,
          'lblBudgetTypeList'
        )} = ${this.specificCriteriaService.getBudgetTypeLabel(
          this.specificCriteriaService.selectedBudgetTypeCode()
        )}`;
    }

    if (this.showRGDControls()) {
      title +=
        `${this.appService.getTranslation(
          language,
          'lblBudgetType'
        )} = ${this.specificCriteriaService.getBudgetTypeRGDLabel(
          this.specificCriteriaService.selectedBudgetTypeCodeRGD()
        )} / ` +
        `${this.appService.getTranslation(
          language,
          'lblBudgetStatus'
        )} = ${this.specificCriteriaService.getBudgetStatusLabel(
          this.specificCriteriaService.selectedBudgetStatusCode()
        )} / ` +
        `${this.appService.getTranslation(
          language,
          'lblExpenseType'
        )} = ${this.specificCriteriaService.getExpenseTypeLabel(
          this.specificCriteriaService.selectedExpenseTypeCode()
        )}`;
    }

    if (this.showLeaseLitigationControl()) {
      title += `${this.appService.getTranslation(
        language,
        'lblSelectLeaseDate'
      )} = ${this.specificCriteriaService.getLeaseLitigationLabel(
        this.specificCriteriaService.selectedLeaseLitigationCode()
      )}`;
    }

    if (this.showUnitTypesControls()) {
      const formattedUnitTypes = this.specificCriteriaService
        .selectedUnitTypeListCode()
        .map((item: SelectDynamicData) => item.value)
        .join(', ');
      const formattedVacancyTypes = this.specificCriteriaService
        .selectedVacancyTypeListCode()
        .map((item: SelectDynamicData) => item.value)
        .join(', ');
      this.isPanelHeaderActive = true;
      title +=
        `${this.appService.getTranslation(
          language,
          'lblUnitTypes'
        )} = ` + (formattedUnitTypes.length > 0 ? `${formattedUnitTypes}` : `${this.appService.getTranslation(
          language,
          'NO_FILTER'
        )}`) +
        ` / ${this.appService.getTranslation(
          language,
          'lblVacancyTypes'
        )} = ` + (formattedVacancyTypes.length > 0 ? `${formattedVacancyTypes}` : `${this.appService.getTranslation(
          language,
          'NO_FILTER'
        )}`);
    }

    if (this.showVacancyAnalysisControls() || this.showVacancyPhysicalControls() || this.showVacancyPhysicalPercentControls() || this.showVacancyPhysicalFinancialControls()) {
      this.isPanelHeaderActive = true;
      const formattedNaturesOfOccupancy = this.specificCriteriaService
        .selectedNaturesOfOccupancy()
        .map((item: SelectDynamicData) => item.value)
        .join(', ');
      title +=
        `${this.appService.getTranslation(
          language,
          'lblSelectVacancyRateSup'
        )} ${this.specificCriteriaService.selectedVacancyRate()} / ` +
        `${this.appService.getTranslation(
          language,
          'lblSelectVacancyAreaSup'
        )} ${this.specificCriteriaService.selectedVacancyArea()} / ` +
        `${this.appService.getTranslation(
          language,
          'lblSelectVacancyMonths'
        )} ${this.specificCriteriaService.selectedVacancyMonths()} / ` +
        `${this.appService.getTranslation(
          language,
          'lblNaturesOfOccupancy'
        )}` + (formattedNaturesOfOccupancy.length > 0 ? `${formattedNaturesOfOccupancy} ` : ` ${this.appService.getTranslation(
          language,
          'NO_FILTER'
        )}`)
        ;
    }

    if (this.showPortfolioEvolutionControls()) {
      title +=
        `${this.appService.getTranslation(
          language,
          'lblReferenceYear'
        )} = ${this.specificCriteriaService
          .selectedReferenceYear()
          ?.toString()} `;
    }

    if (this.showYieldMonitoringControls()) {
      title +=
      `${this.appService.getTranslation(
        language,
        'lblSelectHeadlineNetInitYield'
      )} ${this.specificCriteriaService.selectedHeadlineNetInitYield()} / ` +
      `${this.appService.getTranslation(
        language,
        'lblSelectEffectiveNetInitYield'
      )} ${this.specificCriteriaService.selectedEffectiveNetInitYield()} / `;
    }

    if (this.showPropertyValuationControls()) {
      title +=
        `${this.appService.getTranslation(language, 'lblCutOffDate')} : ` +
        `${this.selectedCutOffDate() !== null ? this.datePipe.transform(this.selectedCutOffDate(), this.dateFormat) : this.appService.getTranslation(language,'NO_FILTER')} / ` +
        `${this.appService.getTranslation(language, 'lblCutOffRevisionDate')} : ` +
        `${this.selectedCutOffRevisionDate() !== null ? this.datePipe.transform(this.selectedCutOffRevisionDate(), this.dateFormat) : this.appService.getTranslation(language,'NO_FILTER')} `
    }

    return title;
  });
}
