import { Routes } from '@angular/router';
import { AuthGuard } from './shared/guard/auth.guard';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { Test1Component } from './tests/test1.component';
import { AddModelReportComponent } from './add-model-report/add-model-report.component';
import { ReportComponent } from './reports/report/report.component';
import { PatrimonyComponent } from './graphs/patrimony/patrimony.component';
import { OccupancyComponent } from './graphs/occupancy/occupancy.component';
import { IncomeComponent } from './graphs/income/income.component';
import { PaiementComponent } from './graphs/paiement/paiement.component';
import { RiskComponent } from './graphs/risk/risk.component';
import { LeaseRepositoryComponent } from './parameters/lease-repository/lease-repository.component';
import { ManageCustomReportComponent } from './parameters/manage-custom-report/manage-custom-report.component';
import { AccountComponent } from './parameters/account/account.component';
import { PasswordRecoveryComponent } from './parameters/account/password-recovery/password-recovery.component';
import { ApiTestsComponent } from './tests/api-tests/api-tests.component';

export const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'reports/report/:reportcode', component: ReportComponent, canActivate: [AuthGuard] },
  { path: 'reports/report/:reportcode/:reportType', component: ReportComponent, canActivate: [AuthGuard] },
  { path: 'reports/report/:reportcode/:idReportTemplate', component: ReportComponent, canActivate: [AuthGuard] },
  { path: 'graphs/patrimony', component: PatrimonyComponent, canActivate: [AuthGuard] },
  { path: 'graphs/occupancy', component: OccupancyComponent, canActivate: [AuthGuard] },
  { path: 'graphs/income', component: IncomeComponent, canActivate: [AuthGuard] },
  { path: 'graphs/paiement', component: PaiementComponent, canActivate: [AuthGuard] },
  { path: 'graphs/risk', component: RiskComponent, canActivate: [AuthGuard] },
  { path: 'settings/lease-repository', component: LeaseRepositoryComponent, canActivate: [AuthGuard] },
  { path: 'model/:id_report_template/:report_code', component: AddModelReportComponent, canActivate: [AuthGuard] },
  { path: 'model', component: AddModelReportComponent, canActivate: [AuthGuard] },
  { path: 'settings/customReport', component: ManageCustomReportComponent, canActivate: [AuthGuard] },
  { path: 'settings/account', component: AccountComponent, canActivate: [AuthGuard] },
  { path: 'settings/account/passwordRecovery', component: PasswordRecoveryComponent },
  { path: 'test1', component: Test1Component, canActivate: [AuthGuard] },
  { path: 'tests/api', component: ApiTestsComponent, canActivate: [AuthGuard] },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
];
