import { Component, inject } from '@angular/core';
import { GraphService } from '../../shared/service/graph.service';
import { PlotlyModule } from 'angular-plotly.js';
import { CardGraphComponent } from '../card-graph/card-graph.component';
import { SidenavService } from '../../shared/service/sidenav.service';

@Component({
  selector: 'app-occupancy',
  standalone: true,
  imports: [PlotlyModule, CardGraphComponent],
  templateUrl: './occupancy.component.html',
  styleUrl: './occupancy.component.scss'
})
export class OccupancyComponent {
  private grahService = inject(GraphService);
  private sidenavService = inject(SidenavService);

  private plotlyGraphDimensions = {
    width: 700,
    height: 400
  };

  plotlyConfig = { displaylogo: false };

  private commonLayout = {
    ...this.plotlyGraphDimensions,
    font: {
      family: 'Roboto',
      size: 16
    }
  };

  // Occupancy
  occupancyAreaLayout = { ...this.commonLayout };
  occupancyAreaData = this.grahService.occupancyAreaData;

  occupancyError = this.grahService.occupancyError;

  // Occupancy evolution
  areaEvolutionLayout = {
    ...this.commonLayout,
    barmode: 'stack',
    xaxis: {
      type: 'category',
      tickangle: -45,
    },
    hoverlabel: {
      namelength: -1
    }
  };
  areaEvolutionData = this.grahService.areaEvolutionData;

  occupancyEvolutionError = this.grahService.occupancyEvolutionError;

  ngOnInit(): void {
    this.sidenavService.showSidenav = true;
    this.sidenavService.showBuildingsList.set(false);
  }

}
